import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";
import { Oval } from "react-loader-spinner"; // Import loader

const BillsPropertyPage = () => {
  const [waterBill, setWaterBill] = useState(null);
  const [trashBill, setTrashBill] = useState(null); // Add state for trash bills
  const [token, setToken] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [rentalId, setRentalId] = useState("");
  const [isAddWaterBillModalOpen, setIsAddWaterBillModalOpen] = useState(false);
  const [previousUnits, setPreviousUnits] = useState("");
  const [currentUnits, setCurrentUnits] = useState("");
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedWaterBill, setSelectedWaterBill] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const [trashAmount, setTrashAmount] = useState(""); // State for trash bill amount
  const [isAddTrashBillModalOpen, setIsAddTrashBillModalOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const storedToken = localStorage.getItem("token");
      const storedPropertyId = localStorage.getItem("selectedPropertyId");
      const storedRentalId = localStorage.getItem("selectedRentalId");

      if (storedToken && storedPropertyId && storedRentalId) {
        setToken(storedToken);
        setPropertyId(storedPropertyId);
        setRentalId(storedRentalId);

        try {
          // Fetch Water Bill Data
          const waterBillResponse = await axios.get(
            `https://backend.inyumba.co.ke/iNyumba/admin/waterbill/getspecificbyrental/${storedRentalId}`,
            {
              headers: {
                Authorization: `Bearer ${storedToken}`,
              },
            }
          );
          setWaterBill(waterBillResponse.data.response);

          const NewWaterBillId =
            waterBillResponse.data.response[0].waterbill_id;
          localStorage.setItem("updatedWaterBillId", NewWaterBillId);

          // Fetch Trash Bill Data
          const trashBillResponse = await axios.get(
            `https://backend.inyumba.co.ke/iNyumba/admin/trashbill/getspecificbyrental/${storedRentalId}`,
            {
              headers: {
                Authorization: `Bearer ${storedToken}`,
              },
            }
          );
          setTrashBill(trashBillResponse.data.response);
        } catch (error) {
          console.error("Error fetching bills:", error);
        }
      }
    };

    fetchData();
  }, []);

  const handleUpdateWaterBill = async (bill) => {
    if (!bill) {
      console.error("Water bill is missing.");
      return;
    }

    // Extract the water bill data
    const selectedBill = waterBill.find((item) => item.waterbill_id === bill);

    // Set the selected water bill
    setSelectedWaterBill({
      previous_units: selectedBill.currentunits, // Set previous units
      current_units: "", // Set current units
    });
    setIsUpdateModalOpen(true); // Open the update modal
  };

  const handleUpdateWaterBillSubmit = async () => {
    setIsLoading(true); // Set loading state to true
    const token = localStorage.getItem("token");
    const PropertyId = localStorage.getItem("selectedPropertyId");
    const WaterBillId = localStorage.getItem("updatedWaterBillId"); // Retrieve from localStorage

    // Prepare the data to be sent in the request body
    const requestData = {
      previous_units: selectedWaterBill.previous_units,
      current_units: selectedWaterBill.current_units,
    };

    // Logic for submitting the updated water bill
    try {
      await axios.patch(
        `https://backend.inyumba.co.ke/iNyumba/admin/waterbill/update/${WaterBillId}/${PropertyId}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const storedRentalId = localStorage.getItem("selectedRentalId");
      const response = await axios.get(
        `https://backend.inyumba.co.ke/iNyumba/admin/waterbill/getspecificbyrental/${storedRentalId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWaterBill(response.data.response);
      toast.success(response.data.response);

      // Update localStorage with the latest water bill ID
      localStorage.setItem(
        "updatedWaterBillId",
        response.data.response.waterbill_id
      );

      setIsLoading(false); // Set loading state to true

      setIsUpdateModalOpen(false); // Close the update modal after submission
    } catch (error) {
      setIsLoading(false); // Set loading state to true
      toast.error(error.response.data.response);
      console.error("Error updating water bill:", error);
    }
  };

  const handleCreateBill = async () => {
    setIsLoading(true); // Set loading state to true
    const token = localStorage.getItem("token");
    const propertyId = localStorage.getItem("selectedPropertyId");
    const rentalId = localStorage.getItem("selectedRentalId");
    try {
      const response = await axios.post(
        `https://backend.inyumba.co.ke/iNyumba/admin/bill/create-bill/${propertyId}/${rentalId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(-1);
      setIsLoading(false); // Set loading state to true
      toast.success(response.data.response);
    } catch (error) {
      setIsLoading(false); // Set loading state to true
      console.error("Error creating bill:", error);
      toast.error(error.response.data.response);
    }
  };

  const handleAddWaterBill = async () => {
    setIsLoading(true); // Set loading state to true
    const token = localStorage.getItem("token");
    const propertyId = localStorage.getItem("selectedPropertyId");
    const rentalId = localStorage.getItem("selectedRentalId");

    // Prepare the data to be sent in the request body
    const requestData = {
      previous_units: previousUnits,
      current_units: currentUnits,
    };

    try {
      const response = await axios.post(
        `https://backend.inyumba.co.ke/iNyumba/admin/waterbill/create/${propertyId}/${rentalId}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Set the content type to JSON
          },
        }
      );
      setWaterBill(response.data.response);
      toast.success(response.data.response);
      setIsLoading(false); // Set loading state to true
      setIsAddWaterBillModalOpen(false); // Close modal after adding water bill
    } catch (error) {
      setIsLoading(false); // Set loading state to true
      toast.error(error.response.data.response);
      console.error("Error adding water bill:", error);
    }
  };

  const handleAddTrashBill = async () => {
    setIsLoading(true); // Set loading state to true
    const token = localStorage.getItem("token");
    const propertyId = localStorage.getItem("selectedPropertyId");
    const rentalId = localStorage.getItem("selectedRentalId");

    // // Prepare data for trash bill
    // const requestData = {
    //   amount: trashAmount,
    // };

    try {
      const response = await axios.post(
        `https://backend.inyumba.co.ke/iNyumba/admin/trashbill/create/${propertyId}/${rentalId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      setTrashBill(response.data.response); // Update trash bill data
      toast.success(response.data.response);
      setIsLoading(false); // Set loading state to false
      setIsAddTrashBillModalOpen(false); // Close modal after adding trash bill
    } catch (error) {
      setIsLoading(false); // Set loading state to false
      toast.error(error.response.data.response);
      console.error("Error adding trash bill:", error);
    }
  };

  const handleUpdateTrashBill = async (billId) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    // const requestData = {
    //   amount: trashAmount,
    // };

    try {
      await axios.patch(
        `https://backend.inyumba.co.ke/iNyumba/admin/trashbill/update/${billId}/${propertyId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Trash Bill updated successfully");
      setIsLoading(false);
      setIsAddTrashBillModalOpen(false); // Close modal after update
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.response);
      console.error("Error updating trash bill:", error);
    }
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Bills</h1>

          {waterBill && waterBill.length > 0 ? (
            <div className="bg-white shadow-md rounded-md p-4">
              <p>
                <strong>Previous Units:</strong> {waterBill[0].previousunits}
              </p>
              <p>
                <strong>Current Units:</strong> {waterBill[0].currentunits}
              </p>
              <button
                onClick={() => handleUpdateWaterBill(waterBill[0].waterbill_id)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
              >
                Latest Water Bill
              </button>
              {trashBill && trashBill.length > 0 ? (
                <div className="bg-white shadow-md rounded-md p-4 mt-4">
                  <p>
                    <strong>Amount:</strong> {trashBill[0].amount}
                  </p>
                  <button
                    onClick={() =>
                      handleUpdateTrashBill(trashBill[0].trashbillid)
                    }
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
                  >
                    Latest Trash Bill
                  </button>
                </div>
              ) : (
                <div className="bg-white shadow-md rounded-md p-4 mt-4">
                  <p className="mb-2">
                    Please make sure you add or post a trash bill before
                    clicking the Post button.
                  </p>
                  <button
                    onClick={() => setIsAddTrashBillModalOpen(true)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Add Trash Bill
                  </button>
                </div>
              )}

              {isAddTrashBillModalOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                  <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div
                      className="fixed inset-0 transition-opacity"
                      aria-hidden="true"
                    >
                      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                    <span
                      className="hidden sm:inline-block sm:align-middle sm:h-screen"
                      aria-hidden="true"
                    >
                      &#8203;
                    </span>
                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                          Add Trash Bill
                        </h3>
                        {/* <div className="mb-4">
                        <label
                          htmlFor="trashAmount"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Trash Amount:
                        </label>
                        <input
                          type="number"
                          id="trashAmount"
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          value={trashAmount}
                          onChange={(e) => setTrashAmount(e.target.value)}
                        />
                      </div> */}
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                          onClick={handleAddTrashBill}
                          disabled={isLoading}
                          className={`${
                            isLoading
                              ? "bg-gray-500"
                              : "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
                          }`}
                        >
                          {isLoading ? (
                            <Oval color="#fff" height={20} width={20} />
                          ) : (
                            "Add Trash Bill"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <button
                onClick={handleCreateBill}
                disabled={isLoading}
                className={`${
                  isLoading
                    ? "bg-gray-500"
                    : "bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2 ml-2"
                } text-white py-2 px-4 rounded-md focus:outline-none`}
              >
                {isLoading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="white"
                    visible={true}
                    ariaLabel="loading-indicator"
                    secondaryColor="white"
                  />
                ) : (
                  " Post Bill to User"
                )}
              </button>
            </div>
          ) : (
            <div className="bg-white shadow-md rounded-md p-4">
              <p className="mb-2">
                Please make sure you add water bill or post water bill before
                clicking the Post button.
              </p>
              <button
                onClick={() => setIsAddWaterBillModalOpen(true)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Add Water Bill
              </button>
            </div>
          )}

          <button
            onClick={() => navigate(-1)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
          >
            Go Back
          </button>

          {/* Add Water Bill Modal */}
          {isAddWaterBillModalOpen && (
            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                  className="fixed inset-0 transition-opacity"
                  aria-hidden="true"
                >
                  <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                          Add Water Bill
                        </h3>
                        <div className="mb-4">
                          <label
                            htmlFor="previousUnits"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Previous Units:
                          </label>
                          <input
                            type="number"
                            id="previousUnits"
                            className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            value={previousUnits}
                            onChange={(e) => setPreviousUnits(e.target.value)}
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="currentUnits"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Current Units:
                          </label>
                          <input
                            type="number"
                            id="currentUnits"
                            className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            value={currentUnits}
                            onChange={(e) => setCurrentUnits(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      onClick={handleAddWaterBill}
                      disabled={isLoading}
                      className={`${
                        isLoading
                          ? "bg-gray-500"
                          : "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                      } text-white py-2 px-4 rounded-md focus:outline-none`}
                    >
                      {isLoading ? (
                        <Oval
                          height={20}
                          width={20}
                          color="white"
                          visible={true}
                          ariaLabel="loading-indicator"
                          secondaryColor="white"
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>

                    <button
                      onClick={() => setIsAddWaterBillModalOpen(false)}
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Update Water Bill Modal */}
          {isUpdateModalOpen && selectedWaterBill && (
            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                  className="fixed inset-0 transition-opacity"
                  aria-hidden="true"
                >
                  <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                          Update Water Bill
                        </h3>
                        <div className="mb-4">
                          <label
                            htmlFor="previousUnits"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Previous Units:
                          </label>
                          <input
                            type="text"
                            id="previousUnits"
                            className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            value={selectedWaterBill.previous_units}
                            readOnly
                          />
                        </div>
                        <div className="mb-4">
                          <div className="mb-4">
                            <label
                              htmlFor="currentUnits"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Current Units:
                            </label>
                            <input
                              type="number"
                              id="currentUnits"
                              className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              value={selectedWaterBill.current_units}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                setSelectedWaterBill({
                                  ...selectedWaterBill,
                                  current_units: inputValue,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      onClick={handleUpdateWaterBillSubmit}
                      disabled={isLoading}
                      className={`${
                        isLoading
                          ? "bg-gray-500"
                          : "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                      } text-white py-2 px-4 rounded-md focus:outline-none`}
                    >
                      {isLoading ? (
                        <Oval
                          height={20}
                          width={20}
                          color="white"
                          visible={true}
                          ariaLabel="loading-indicator"
                          secondaryColor="white"
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                    <button
                      onClick={() => setIsUpdateModalOpen(false)}
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default BillsPropertyPage;
