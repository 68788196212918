import React, { useState } from "react";
import "react-pro-sidebar/dist/css/styles.css";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
  SubMenu,
} from "react-pro-sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faSignOutAlt,
  faChartBar,
  faHandHoldingUsd,
  faTachometerAlt,
  faEnvelope,
  faCogs,
  faChevronLeft,
  faChevronRight,
  faBuilding,
  faIdCard,
  faDollarSign,
  faHome,
  faVideo,
  faCamera,
  faMoneyCheck,
  faUserTie,
  faUserSecret,
  faCog,
  faPiggyBank,
  faEye,
  faFileAlt,
  faBell,
  faMoneyBillWave,
  faKey,
  faUsers,
  faTools,
  faTrash,
  faClipboardList,
  faFileInvoiceDollar,
  faWater,
  faPlusCircle,
  faSearch,
  faEdit,
  faTrashAlt,
  faBalanceScale,
  faCoins,
  faPercentage,
  faArrowRightToBracket,
  faArrowRightFromBracket,
  faUniversity,
  faWallet,
  faLink,
  faSyncAlt,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";
const Home = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false); // Manage sidebar state
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userEmail = userData.email;
  const handleProfileClick = () => {
    // Redirect to profile screen
    navigate("/admin/profile");
  };

  const handleLogoutClick = () => {
    // Redirect to logout screen
    navigate("/logout");
  };

  const handleUserBalancesClick = () => {
    handleNavigationWithEmailCheck("/admin/userbalances");
  };

  const handleOwnerBalancesClick = () => {
    handleNavigationWithEmailCheck("/admin/ownerbalances");
  };

  const handleReservationsClick = () => {
    handleNavigationWithEmailCheck("/admin/reservations");
  };

  const handleBillClick = () => {
    handleNavigationWithEmailCheck("/admin/bill");
  };

  const handleWaterBillClick = () => {
    handleNavigationWithEmailCheck("/admin/waterbill");
  };

  const handleVacatingClick = () => {
    handleNavigationWithEmailCheck("/admin/vacating");
  };

  const handleVideosClick = () => {
    handleNavigationWithEmailCheck("/admin/videos");
  };

  const handlePhotosClick = () => {
    handleNavigationWithEmailCheck("/admin/photos");
  };

  const handlePromocodesClick = () => {
    handleNavigationWithEmailCheck("/admin/promocodes");
  };

  const handleAllUsersClick = () => {
    handleNavigationWithEmailCheck("/admin/users");
  };

  const handleBank = () => {
    handleNavigationWithEmailCheck("/admin/bank");
  };

  const handlePropertiesClick = () => {
    handleNavigationWithEmailCheck("/admin/propertypage");
  };

  const handleRentalsClick = () => {
    handleNavigationWithEmailCheck("/admin/rentals");
  };

  const handleMoneyOutClick = () => {
    handleNavigationWithEmailCheck("/admin/moneyout");
  };

  const handleMoneyInClick = () => {
    handleNavigationWithEmailCheck("/admin/moneyin");
  };

  const handleDepositClick = () => {
    handleNavigationWithEmailCheck("/admin/deposit");
  };

  const handleMonthlyFeesClick = () => {
    handleNavigationWithEmailCheck("/admin/monthlyfees");
  };

  const handlePropertyKYCClick = () => {
    handleNavigationWithEmailCheck("/admin/propertykyc");
  };

  const handlePropertyHunter = () => {
    handleNavigationWithEmailCheck("/admin/propertyhunter");
  };

  const handleViewSettlementUser = () => {
    handleNavigationWithEmailCheck("/admin/viewusersettlement");
  };

  const handleViewSettlementLandlord = () => {
    handleNavigationWithEmailCheck("/admin/viewlandlordsettlement");
  };

  const handleViewSettlementHunter = () => {
    handleNavigationWithEmailCheck("/admin/viewpropertyhuntersettlement");
  };
  const handleViewDepositSettlements = () => {
    handleNavigationWithEmailCheck("/admin/admincompletesettlements");
  };

  const handleViewNotifications = () => {
    handleNavigationWithEmailCheck("/admin/viewnotifications");
  };
  const handleRecordsView = () => {
    handleNavigationWithEmailCheck("/admin/recordsview");
  };
  const handleViewAuditLogs = () => {
    handleNavigationWithEmailCheck("/admin/auditlogs");
  };
  const handleAgencyViewingFee = () => {
    handleNavigationWithEmailCheck("/admin/agencyviewing");
  };
  const handleMaintenaceRequests = () => {
    handleNavigationWithEmailCheck("/admin/maintenancerequest");
  };
  const handleWithdrawals = () => {
    handleNavigationWithEmailCheck("/admin/withdrawals");
  };
  const handleVerifyCodes = () => {
    handleNavigationWithEmailCheck("/admin/viewverifycodes");
  };
  const handleAdminSettlementsView = () => {
    handleNavigationWithEmailCheck("/admin/viewadminsettlements");
  };
  const handleServiceCharge = () => {
    handleNavigationWithEmailCheck("/admin/serviceratecharge");
  };
  const handleWithdrawalLink = () => {
    handleNavigationWithEmailCheck("/admin/withdrawalshelplandlord");
  };

  const handleAddReservationBills = () => {
    handleNavigationWithEmailCheck("/admin/addreservationbills");
  };

  const handleCheckWaterUnits = () => {
    handleNavigationWithEmailCheck("/admin/checkwaterunits");
  };

  const handleUpdateLandlordBalances = () => {
    handleNavigationWithEmailCheck("/admin/updatelandlordbalance");
  };
  const handleUpdateUserBalances = () => {
    handleNavigationWithEmailCheck("/admin/updateuserbalances");
  };

  const handleUpdateBills = () => {
    handleNavigationWithEmailCheck("/admin/updatebills");
  };

  const handleEmailSending = () => {
    handleNavigationWithEmailCheck("/admin/emailsender");
  };

  const handlePropertyTransactions = () => {
    handleNavigationWithEmailCheck("/admin/transactionsviewpage");
  };

  const handleTrashUnitsPrice = () => {
    handleNavigationWithEmailCheck("/admin/checktrashprice");
  };

  const handleOfflinePayments = () => {
    handleNavigationWithEmailCheck("/admin/viewofflinepayments");
  };

  const handleAllTrashUnitsPrice = () => {
    handleNavigationWithEmailCheck("/admin/alltrashunitsprice");
  };

  const handleViewTrashBills = () => {
    handleNavigationWithEmailCheck("/admin/viewtrashbills");
  };
  const handleManualReservationsClick = () => {
    handleNavigationWithEmailCheck("/admin/manualreservations");
  };

  const handleSMSSending = () => {
    handleNavigationWithEmailCheck("/admin/smssender");
  };

  const handleAddManualSettlement = () => {
    handleNavigationWithEmailCheck("/admin/addmanualsettlement");
  };
  const handlePropertyWaterBillClick = () => {
    handleNavigationWithEmailCheck("/admin/propertywaterbill");
  };
  const handlePropertyTrashBill = () => {
    handleNavigationWithEmailCheck("/admin/propertytrashbill");
  };

  const handleNavigationWithEmailCheck = (targetRoute) => {
    if (userEmail === "xavierkioko12@gmail.com") {
      navigate(targetRoute);
    } else {
      alert("You are not authorized to navigate to this page.");
    }
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <div className="flex ">
            {/* Sidebar */}
            <ProSidebar collapsed={collapsed}>
              {/* Sidebar Header */}
              <SidebarHeader>
                <div className="flex justify-between items-center p-4">
                  <h2
                    className={`text-xl font-semibold text-white ${
                      collapsed ? "hidden" : "block"
                    }`}
                  >
                    Admin Dashboard
                  </h2>
                  <button
                    onClick={() => setCollapsed(!collapsed)}
                    className="text-white bg-transparent border-none cursor-pointer p-2"
                  >
                    {collapsed ? (
                      <FontAwesomeIcon icon={faChevronRight} size="lg" />
                    ) : (
                      <FontAwesomeIcon icon={faChevronLeft} size="lg" />
                    )}
                  </button>
                </div>
              </SidebarHeader>

              {/* Sidebar Content */}
              <SidebarContent>
                <Menu iconShape="circle">
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faUser} />}
                    onClick={handleProfileClick}
                  >
                    Profile
                  </MenuItem>

                  <SubMenu
                    title="Finances"
                    icon={<FontAwesomeIcon icon={faChartBar} />}
                  >
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faBalanceScale} />}
                      onClick={handleUserBalancesClick}
                    >
                      User Balances
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faCoins} />}
                      onClick={handleOwnerBalancesClick}
                    >
                      Owner Balances
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faPercentage} />}
                      onClick={handlePromocodesClick}
                    >
                      Promocodes
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
                      onClick={handleMoneyOutClick}
                    >
                      MoneyOut
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faArrowRightToBracket} />}
                      onClick={handleMoneyInClick}
                    >
                      MoneyIn
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faDollarSign} />}
                      onClick={handleMonthlyFeesClick}
                    >
                      MonthlyFees
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faUniversity} />}
                      onClick={handleBank}
                    >
                      Bank
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faWallet} />}
                      onClick={handleWithdrawals}
                    >
                      Withdrawals
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faLink} />}
                      onClick={handleWithdrawalLink}
                    >
                      Withdrawal Link Landlord
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faSyncAlt} />}
                      onClick={handleUpdateLandlordBalances}
                    >
                      UpdateLandlordBalances
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faSyncAlt} />}
                      onClick={handleUpdateUserBalances}
                    >
                      UpdateUserBalances
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faReceipt} />}
                      onClick={handleOfflinePayments}
                    >
                      OfflinePayments
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faHome} />}
                      onClick={handlePropertyTransactions}
                    >
                      PropertyTransactions
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faUserTie} />}
                      onClick={handlePropertyHunter}
                    >
                      PropertyHunter
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faUserTie} />}
                      onClick={handleAddManualSettlement}
                    >
                      Add Manual Settlement
                    </MenuItem>
                  </SubMenu>

                  <SubMenu
                    title="Management"
                    icon={<FontAwesomeIcon icon={faClipboardList} />}
                  >
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faClipboardList} />}
                      onClick={handleReservationsClick}
                    >
                      Reservations
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faClipboardList} />}
                      onClick={handleManualReservationsClick}
                    >
                      Manual Reservations
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
                      onClick={handleBillClick}
                    >
                      Bill
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faWater} />}
                      onClick={handleWaterBillClick}
                    >
                      WaterBill
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faWater} />}
                      onClick={handlePropertyWaterBillClick}
                    >
                      PropertyWaterBill
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faTrash} />}
                      onClick={handlePropertyTrashBill}
                    >
                      PropertyTrashBills
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faPlusCircle} />}
                      onClick={handleAddReservationBills}
                    >
                      AddBills
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faSearch} />}
                      onClick={handleCheckWaterUnits}
                    >
                      CheckWaterUnits
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faEdit} />}
                      onClick={handleUpdateBills}
                    >
                      UpdateBills
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faTrashAlt} />}
                      onClick={handleTrashUnitsPrice}
                    >
                      TrashUnitsPrice
                    </MenuItem>
                  </SubMenu>
                  <SubMenu
                    title="Views"
                    icon={<FontAwesomeIcon icon={faEye} />}
                  >
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faFileAlt} />}
                      onClick={handleViewAuditLogs}
                    >
                      ViewAuditLogs
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faFileAlt} />}
                      onClick={handleRecordsView}
                    >
                      View Records
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faBell} />}
                      onClick={handleViewNotifications}
                    >
                      ViewNotifications
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faMoneyBillWave} />}
                      onClick={handleAgencyViewingFee}
                    >
                      ViewAgencyViewingFee
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faPiggyBank} />}
                      onClick={handleViewDepositSettlements}
                    >
                      ViewDepositSettlements
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faKey} />}
                      onClick={handleVerifyCodes}
                    >
                      VerifyCodes
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faUsers} />}
                      onClick={handleAllUsersClick}
                    >
                      ViewUsers
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faTools} />}
                      onClick={handleMaintenaceRequests}
                    >
                      MaintenaceRequests
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faTrash} />}
                      onClick={handleViewTrashBills}
                    >
                      ViewTrashBills
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faDollarSign} />}
                      onClick={handleAllTrashUnitsPrice}
                    >
                      AllTrashUnitsPrice
                    </MenuItem>
                  </SubMenu>
                  <SubMenu
                    title="Settlements"
                    icon={<FontAwesomeIcon icon={faMoneyCheck} />}
                  >
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faUser} />}
                      onClick={handleViewSettlementUser}
                    >
                      ViewSettlementUser
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faUserTie} />}
                      onClick={handleViewSettlementLandlord}
                    >
                      ViewSettlementLandlord
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faUserSecret} />}
                      onClick={handleViewSettlementHunter}
                    >
                      ViewSettlementHunter
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faCog} />}
                      onClick={handleAdminSettlementsView}
                    >
                      AdminSettlementsView
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faSignOutAlt} />}
                      onClick={handleVacatingClick}
                    >
                      Vacating
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faPiggyBank} />}
                      onClick={handleDepositClick}
                    >
                      Deposit
                    </MenuItem>
                  </SubMenu>
                  <SubMenu
                    title="Properties"
                    icon={<FontAwesomeIcon icon={faBuilding} />}
                  >
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faBuilding} />}
                      onClick={handlePropertiesClick}
                    >
                      Properties
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faIdCard} />}
                      onClick={handlePropertyKYCClick}
                    >
                      PropertyKYC
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faDollarSign} />}
                      onClick={handleServiceCharge}
                    >
                      ServiceCharge
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faHome} />}
                      onClick={handleRentalsClick}
                    >
                      Rentals
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faVideo} />}
                      onClick={handleVideosClick}
                    >
                      Videos
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faCamera} />}
                      onClick={handlePhotosClick}
                    >
                      Photos
                    </MenuItem>
                  </SubMenu>
                  <SubMenu
                    title="Communication"
                    icon={<FontAwesomeIcon icon={faEnvelope} />}
                  >
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faEnvelope} />}
                      onClick={handleEmailSending}
                    >
                      Send Email
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faEnvelope} />}
                      onClick={handleSMSSending}
                    >
                      Send SMS
                    </MenuItem>
                  </SubMenu>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faSignOutAlt} />}
                    onClick={handleLogoutClick}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </SidebarContent>

              {/* Sidebar Footer */}
              <SidebarFooter>
                <div className="text-white text-center p-4">
                  <p className="text-sm">© 2024 Reivax Softwares</p>
                  <p className="text-sm">All Rights Reserved</p>
                </div>
              </SidebarFooter>
            </ProSidebar>
          </div>
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

const Button = ({ children, onClick }) => (
  <button
    onClick={onClick}
    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
  >
    {children}
  </button>
);

export default Home;
