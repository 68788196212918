import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"; // Import icons
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";
import { Oval } from "react-loader-spinner"; // Import loader

const WithdrawalsLandlordAdminPage = () => {
  const [balance, setBalance] = useState(null);
  const [showBalance, setShowBalance] = useState(true);
  const [properties, setProperties] = useState([]); // State to store all properties
  const [selectedProperty, setSelectedProperty] = useState(""); // State for selected property
  const [ownerId, setOwnerId] = useState(""); // State for owner ID
  const [amountPaid, setAmountPaid] = useState(""); // State for amount paid
  const [transactionCode, setTransactionCode] = useState(""); // State for transaction code

  const token = localStorage.getItem("token");

  // Fetch properties on component mount
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(
          "https://backend.inyumba.co.ke/iNyumba/admin/properties/recordsall",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data && response.data.response) {
          setProperties(response.data.response);
        }
      } catch (error) {
        console.error("Error fetching properties:", error);
        toast.error("Failed to load properties.");
      }
    };

    fetchProperties();
  }, [token]);

  // Fetch balance when a property is selected
  useEffect(() => {
    const fetchBalance = async () => {
      if (token && selectedProperty) {
        try {
          const response = await axios.get(
            `https://backend.inyumba.co.ke/iNyumba/balance/owner/${selectedProperty}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.data && response.data.response.length > 0) {
            setBalance(response.data.response[0].balance);
          }
        } catch (error) {
          console.error("Error fetching balance:", error);
          toast.error(
            error.response.data.response || "Error fetching balance."
          );
        }
      }
    };

    fetchBalance();
  }, [token, selectedProperty]);

  const toggleShowBalance = () => {
    setShowBalance(!showBalance);
  };

  const handlePropertySelection = (e) => {
    const propertyId = e.target.value;
    const property = properties.find((p) => p.property_id === propertyId);

    if (property) {
      setSelectedProperty(property.property_id); // Set the correct property_id
      setOwnerId(property.owner_id); // Populate owner_id
    }
  };

  const handleManualSettlement = async () => {
    if (!selectedProperty || !ownerId || !amountPaid || !transactionCode) {
      toast.error("All fields are required.");
      return;
    }

    try {
      const response = await axios.post(
        "https://backend.inyumba.co.ke/iNyumba/admin/manualsettlementaddition",
        {
          ownerid: ownerId,
          propertyid: selectedProperty,
          amountpaid: parseFloat(amountPaid),
          transactioncode: transactionCode,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        toast.success("Settlement added successfully.");
        // Reset form
        setAmountPaid("");
        setTransactionCode("");
      }
    } catch (error) {
      console.error("Error adding settlement:", error);
      toast.error(
        error.response?.data?.response || "Failed to add settlement."
      );
    }
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-4">
            Manually Input an Offline Settlement
          </h1>

          <div className="border border-gray-300 rounded-lg p-6 bg-white">
            {/* Balance Section */}
            <div className="mb-6">
              <div className="flex items-center mb-2">
                <span className="mr-2">Available Balance:</span>
                <span className="font-bold text-lg">
                  {showBalance ? balance : "******"}
                </span>
                <button
                  onClick={toggleShowBalance}
                  className="ml-4 focus:outline-none"
                >
                  <FontAwesomeIcon
                    icon={showBalance ? faEyeSlash : faEye}
                    className="text-gray-500"
                  />
                </button>
              </div>
            </div>

            {/* Property Selection */}
            <div className="mb-4">
              <label className="block mb-2 font-semibold">
                Select Property
              </label>
              <select
                onChange={handlePropertySelection}
                className="w-full border border-gray-300 rounded-md p-2 mb-4"
                defaultValue=""
              >
                <option value="" disabled>
                  -- Select Property --
                </option>
                {properties.map((property) => (
                  <option
                    key={property.property_id}
                    value={property.property_id}
                  >
                    {`${property.property_id} - ${property.owner_id}- ${property.title}`}
                  </option>
                ))}
              </select>
            </div>

            {/* Owner ID and Property ID */}
            <div className="mb-4">
              <label className="block mb-2 font-semibold">Owner ID</label>
              <input
                type="text"
                value={ownerId}
                readOnly
                className="w-full border border-gray-300 rounded-md p-2 mb-4 bg-gray-100"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-semibold">Property ID</label>
              <input
                type="text"
                value={selectedProperty}
                readOnly
                className="w-full border border-gray-300 rounded-md p-2 mb-4 bg-gray-100"
              />
            </div>

            {/* Amount Paid */}
            <div className="mb-4">
              <label className="block mb-2 font-semibold">Amount Paid</label>
              <input
                type="number"
                value={amountPaid}
                onChange={(e) => setAmountPaid(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2 mb-4"
                placeholder="Enter Amount Paid"
              />
            </div>

            {/* Transaction Code */}
            <div className="mb-4">
              <label className="block mb-2 font-semibold">
                Transaction Code
              </label>
              <input
                type="text"
                value={transactionCode}
                onChange={(e) => setTransactionCode(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2 mb-4"
                placeholder="Enter Transaction Code"
              />
            </div>

            {/* Submit Button */}
            <button
              onClick={handleManualSettlement}
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              Submit Settlement
            </button>
          </div>
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default WithdrawalsLandlordAdminPage;
