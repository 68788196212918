import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileCsv } from "@fortawesome/free-solid-svg-icons";

const ReservationsPage = () => {
  const [reservations, setReservations] = useState([]);
  const [propertyId, setPropertyId] = useState("");
  const [webUrl, setWebUrl] = useState(""); // State for payment URL
  const [showIframe, setShowIframe] = useState(false); // State for iframe visibility
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [transactionCode, setTransactionCode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalMpesaOpen, setIsModalMpesaOpen] = useState(false);
  const [selectedReservationId, setSelectedReservationId] = useState(null);
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [recordToUpdate, setRecordToUpdate] = useState(null);

  useEffect(() => {
    if (propertyId) {
      fetchReservations();
    }
  }, [propertyId]);

  const fetchReservations = () => {
    const token = localStorage.getItem("token");

    if (token) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/user/reservations/viewAll/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setReservations(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching reservations:", error);
        });
    }
  };

  const handleInputChange = (e) => {
    setPropertyId(e.target.value);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchReservations();
    }
  };

  const handlePayment = (userId) => {
    const token = localStorage.getItem("token");

    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/user/payment/initiate-payment/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Payment initiated successfully");
        setWebUrl(response.data.response); // Set the payment URL from the response
        setShowIframe(true); // Show the iframe
      })
      .catch((error) => {
        console.error("Error initiating payment:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleMpesaPayment = (userId, phoneNumber) => {
    const token = localStorage.getItem("token");

    if (!phoneNumber) {
      toast.error("Please enter a phone number");
      return;
    }

    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/user/mpesapayment/rentpaymentinitiatestk/${userId}`,
        { phoneNumber }, // Send the phone number in the request body
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
        setShowPhoneModal(false); // Close the modal after success
      })
      .catch((error) => {
        console.error("Error initiating mpesa payment:", error);
        toast.error(error.response.data.response);
        setShowPhoneModal(false); // Close the modal on error
      });
  };

  // Function to open the phone number modal
  const handleOpenPhoneModal = (record) => {
    setRecordToUpdate(record); // Set the selected record to update
    setPhoneNumber(""); // Reset phone number input
    setShowPhoneModal(true); // Open the modal
  };

  const handleCloseIframe = () => {
    setShowIframe(false); // Hide the iframe
    setWebUrl(""); // Clear the payment URL
  };

  const handleDownload = (fileType) => {
    const endpoint = `https://backend.inyumba.co.ke/iNyumba/reservationsreports`;

    // Fetch and download file
    axios
      .post(
        endpoint,
        { propertyId }, // Ensure propertyId is sent correctly
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { format: fileType }, // Send format as query parameter
          responseType: "blob", // Important for file download
        }
      )
      .then((response) => {
        const fileExtension = fileType === "pdf" ? "pdf" : "csv";
        const fileName = `inyumbareservations.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up
        toast.success("Download successful");
      })
      .catch((error) => {
        console.error(`Error downloading ${fileType.toUpperCase()}:`, error);
        toast.error(error.response.data.response);
      });
  };

  const handleDownloadAll = (fileType) => {
    const endpoint = `https://backend.inyumba.co.ke/iNyumba/reservationsreportsalltime`;

    // Fetch and download file
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { format: fileType },
        responseType: "blob", // Important for file download
      })
      .then((response) => {
        const fileExtension = fileType === "pdf" ? "pdf" : "csv";
        const fileName = `inyumbareservationrecords.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Download is successfull");
      })
      .catch((error) => {
        console.error(`Error downloading ${fileType.toUpperCase()}:`, error);
        toast.error(error.response.data.response);
      });
  };

  const handleManualUpdate = (userId) => {
    const token = localStorage.getItem("token");

    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/manualcallbacktenants/${userId}`,
        {
          transactioncode: transactionCode, // Pass transactionCode as JSON
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        toast.success("Manual update triggered successfully");
        setIsModalMpesaOpen(false); // Close modal after success
        setIsModalOpen(false); // Close modal after success
        setTransactionCode(null);
      })
      .catch((error) => {
        console.error("Error triggering manual update:", error);
        toast.error(error.response.data.response);
        setIsModalOpen(false); // Close modal after success
        setIsModalMpesaOpen(false); // Close modal after success
        setTransactionCode(null);
      });
  };

  const handleManualMpesaUpdate = (userId) => {
    const token = localStorage.getItem("token");

    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/manualmpesacallbacktenants/${userId}`,
        {
          transactioncode: transactionCode, // Pass transactionCode as JSON
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        toast.success("Manual update triggered successfully");
        setIsModalOpen(false); // Close modal after success
        setIsModalMpesaOpen(false); // Close modal after success
        setTransactionCode(null);
      })
      .catch((error) => {
        console.error("Error triggering manual update:", error);
        toast.error(error.response.data.response);
        setIsModalOpen(false); // Close modal after success
        setIsModalMpesaOpen(false); // Close modal after success
        setTransactionCode(null);
      });
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Reservations</h1>

          <div className="flex space-x-2">
            <button
              onClick={() => handleDownloadAll("csv")}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm"
            >
              <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
              Download All
            </button>
            <button
              onClick={() => handleDownloadAll("pdf")}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
            >
              <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
              Download All
            </button>
          </div>

          <div className="mb-4 flex justify-center">
            <input
              type="text"
              value={propertyId}
              onChange={handleInputChange}
              onKeyPress={handleEnterKeyPress}
              placeholder="Enter Property ID and press Enter..."
              className="border border-gray-300 rounded-md py-2 px-4 w-64 focus:outline-none focus:border-blue-500 mr-2"
            />
            <button
              onClick={fetchReservations}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
            >
              Fetch Reservations
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {/* Download buttons for the property_id */}
            <div className="flex space-x-1 mb-2">
              <button
                onClick={() => handleDownload("csv")}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm"
              >
                <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
                Download Property
              </button>
              <button
                onClick={() => handleDownload("pdf")}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
              >
                <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
                Download Property
              </button>
            </div>
            {reservations.map((reservation) => (
              <div
                key={reservation.room_id}
                className="bg-white shadow-md rounded-md p-4"
              >
                <p>
                  <strong>User Name:</strong> {reservation.user_name}
                </p>
                <p>
                  <strong>Email:</strong> {reservation.email}
                </p>
                <p>
                  <strong>Phone:</strong> {reservation.phone}
                </p>
                <p>
                  <strong>Entry Date:</strong> {reservation.entrydate}
                </p>
                <p>
                  <strong>House Number:</strong> {reservation.house_no}
                </p>
                <p>
                  <strong>Booking Status:</strong> {reservation.booking_status}
                </p>
                <p>
                  <strong>Deposit Paid:</strong> {reservation.deposit_paid}
                </p>
                <p>
                  <strong>Payment Status:</strong> {reservation.payment_status}
                </p>
                {reservation.payment_status === "PENDING" && (
                  <>
                    <button
                      onClick={() => handlePayment(reservation.user_id)}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2"
                    >
                      iPay
                    </button>
                    <button
                      onClick={() => {
                        setSelectedReservationId(reservation.user_id); // Set selected reservation ID
                        setIsModalOpen(true); // Open modal
                      }}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2 ml-2"
                    >
                      ManualCbk iPay
                    </button>

                    <button
                      onClick={() => {
                        // setRecordToUpdate(reservation);
                        handleOpenPhoneModal(reservation); // Open the modal
                      }}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2 ml-2"
                    >
                      Mpesa
                    </button>

                    <button
                      onClick={() => {
                        setSelectedReservationId(reservation.user_id); // Set selected reservation ID
                        setIsModalMpesaOpen(true); // Open modal
                      }}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2 ml-2"
                    >
                      ManualCbk Mpesa
                    </button>
                  </>
                )}
              </div>
            ))}
            {showIframe && webUrl && (
              <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex flex-col items-center justify-center">
                <button
                  onClick={handleCloseIframe}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mb-4"
                >
                  Close
                </button>
                <iframe
                  src={webUrl}
                  className="w-screen h-screen border-none mx-auto"
                  title="Payment Iframe"
                ></iframe>
              </div>
            )}
            {/* Modal */}
            {/* {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
                <h2 className="text-lg font-bold mb-4">
                  Enter Transaction Code
                </h2>
                <input
                  type="text"
                  value={transactionCode}
                  onChange={(e) => setTransactionCode(e.target.value)}
                  placeholder="Enter transaction code"
                  className="border p-3 mb-6 w-full"
                />
                <div className="flex justify-end">
                  <button
                    onClick={() => setIsModalOpen(false)} // Close modal on cancel
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleManualUpdate(reservation.user_id)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )} */}

            {isModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
                  <h2 className="text-lg font-bold mb-4">
                    Enter Transaction Code-Ipay
                  </h2>
                  <input
                    type="text"
                    value={transactionCode}
                    onChange={(e) => setTransactionCode(e.target.value)}
                    placeholder="Enter transaction code"
                    className="border p-3 mb-6 w-full"
                  />
                  <div className="flex justify-end">
                    <button
                      onClick={() => setIsModalOpen(false)} // Close modal on cancel
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        handleManualUpdate(selectedReservationId); // Pass selected reservation ID
                        setIsModalOpen(false); // Close modal after submission
                      }}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}

            {isModalMpesaOpen && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
                  <h2 className="text-lg font-bold mb-4">
                    Enter Transaction Code-Mpesa
                  </h2>
                  <input
                    type="text"
                    value={transactionCode}
                    onChange={(e) => setTransactionCode(e.target.value)}
                    placeholder="Enter transaction code"
                    className="border p-3 mb-6 w-full"
                  />
                  <div className="flex justify-end">
                    <button
                      onClick={() => setIsModalMpesaOpen(false)} // Close modal on cancel
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        handleManualMpesaUpdate(selectedReservationId); // Pass selected reservation ID
                        setIsModalMpesaOpen(false); // Close modal after submission
                      }}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}
            {showPhoneModal && (
              <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50">
                <div className="bg-white p-6 rounded-lg shadow-md w-96">
                  <h2 className="text-2xl font-semibold mb-4">
                    Enter Phone Number
                  </h2>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleMpesaPayment(
                        recordToUpdate.user_id, // Ensure you are using the correct userId
                        phoneNumber
                      ); // Pass phone number to payment function
                    }}
                  >
                    <input
                      type="text"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      placeholder="Enter Phone Number"
                      className="border border-gray-300 rounded py-1 px-3 text-sm mb-4 w-full"
                    />
                    <div className="flex justify-end space-x-2">
                      <button
                        type="button"
                        onClick={() => setShowPhoneModal(false)} // Close modal
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-4 rounded"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default ReservationsPage;
