import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";
import { toast } from "react-toastify";

const PropertyKYCPage = () => {
  const [propertyKycDataList, setPropertyKycDataList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPropertyKycData();
  }, []);

  const fetchPropertyKycData = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        "https://backend.inyumba.co.ke/iNyumba/propertykyc/fetchall",
        config
      );
      setPropertyKycDataList(response.data.response);
    } catch (error) {
      console.error("Error fetching property KYC data:", error);
    }
  };

  const handleUpdate = async (propertyId) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Perform update logic here

      // Example update request
      await axios.patch(
        `https://backend.inyumba.co.ke/iNyumba/propertykyc/update/${propertyId}`,
        {},
        config
      );

      toast.success("Updated succesfully");
      // Refresh property KYC data after update
      fetchPropertyKycData();
    } catch (error) {
      console.error("Error updating property KYC data:", error);
      toast.error("Error updating property KYC data");
    }
  };

  const handleDelete = async (propertyId) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Example delete request
      const response = await axios.delete(
        `https://backend.inyumba.co.ke/iNyumba/propertykyc/delete/${propertyId}`,
        config
      );

      toast.success(response.data.response);

      // Refresh property KYC data after deletion
      fetchPropertyKycData();
    } catch (error) {
      toast.error(error.response.data.response);
      console.error("Error deleting property KYC data:", error);
    }
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Property KYC</h1>
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Property ID
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Business PIN
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Registration Certificate
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    CR12 Certificate
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Utility Bills
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Business Permit
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 bg-gray-50"></th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {propertyKycDataList.map((property) => (
                  <tr key={property.property_id}>
                    <td className="px-6 py-4 whitespace-wrap">
                      {property.property_id}
                    </td>
                    <td className="px-6 py-4 whitespace-wrap">
                      <a
                        href={property.business_pin}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {property.business_pin}
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-wrap">
                      <a
                        href={property.reg_certificate}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {property.reg_certificate}
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-wrap">
                      <a
                        href={property.cr12_certificate}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {property.cr12_certificate}
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-wrap">
                      <a
                        href={property.utility_bills}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {property.utility_bills}
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-wrap">
                      <a
                        href={property.business_permit}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {property.business_permit}
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-wrap">
                      {property.status}
                    </td>
                    <td className="px-6 py-4 whitespace-wrap">
                      <button
                        onClick={() => handleUpdate(property.property_id)}
                        className="text-blue-500 hover:underline mr-4"
                      >
                        Update
                      </button>
                      <button
                        onClick={() => handleDelete(property.property_id)}
                        className="text-red-500 hover:underline"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default PropertyKYCPage;
