import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";

const PropertyWaterBillPage = () => {
  const [propertyId, setPropertyId] = useState("");
  const [waterBills, setWaterBills] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentUnits, setCurrentUnits] = useState("");
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [billToUpdate, setBillToUpdate] = useState(null);

  const fetchWaterBills = () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/admin/waterbill/getall/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setWaterBills(response.data.response);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching water bills:", error);
        toast.error(
          error.response?.data?.response || "Error fetching water bills"
        );
        setIsLoading(false);
      });
  };

  const handleDeleteWaterBill = (waterBillId) => {
    const token = localStorage.getItem("token");
    axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/admin/waterbill/deletewaterbill/${waterBillId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        toast.success("Water bill deleted successfully.");
        fetchWaterBills();
      })
      .catch((error) => {
        console.error("Error deleting water bill:", error);
        toast.error("Failed to delete water bill.");
      });
  };

  const handleUpdateWaterBill = (waterBillId) => {
    const token = localStorage.getItem("token");
    const data = {
      currentunits: currentUnits,
    };

    axios
      .patch(
        `https://backend.inyumba.co.ke/iNyumba/admin/waterbill/updatewaterbillid/${waterBillId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        toast.success("Water bill updated successfully.");
        fetchWaterBills();
        setUpdateModalOpen(false); // Close the update modal after success
        setCurrentUnits(""); // Reset input value
      })
      .catch((error) => {
        console.error("Error updating water bill:", error);
        toast.error("Failed to update water bill.");
        setUpdateModalOpen(false); // Close the update modal after error
        setCurrentUnits(""); // Reset input value
      });
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Property Water Bills</h1>
          <div className="flex mb-4">
            <input
              type="text"
              value={propertyId}
              onChange={(e) => setPropertyId(e.target.value)}
              placeholder="Enter Property ID"
              className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
            />
            <button
              onClick={fetchWaterBills}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
            >
              Search
            </button>
          </div>

          {updateModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <h2 className="text-2xl">Update Water Bill</h2>
                <input
                  type="number"
                  value={currentUnits}
                  onChange={(e) => setCurrentUnits(e.target.value)}
                  placeholder="Enter Current Units"
                  className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                />
                <div className="modal-footer">
                  <button
                    onClick={() =>
                      handleUpdateWaterBill(billToUpdate.waterbill_id)
                    }
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    disabled={!currentUnits}
                  >
                    Update Water Bill
                  </button>
                  <button
                    onClick={() => setUpdateModalOpen(false)}
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded ml-2"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          {isLoading ? (
            <div className="flex justify-center">
              <Oval
                height={40}
                width={40}
                color="blue"
                ariaLabel="loading-indicator"
              />
            </div>
          ) : (
            <table className="mt-4 w-full">
              <thead>
                <tr>
                  <th className="px-4 py-2">Created Date</th>
                  <th className="px-4 py-2">Water Bill ID</th>
                  <th className="px-4 py-2">House Number</th>
                  <th className="px-4 py-2">Rental Id</th>
                  <th className="px-4 py-2">User Id</th>
                  <th className="px-4 py-2">Previous Units</th>
                  <th className="px-4 py-2">Current Units</th>
                  <th className="px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {waterBills.map((bill) => (
                  <tr key={bill.waterbillid}>
                    <td className="border px-4 py-2">{bill.created_at}</td>
                    <td className="border px-4 py-2">{bill.waterbill_id}</td>
                    <td className="border px-4 py-2">{bill.house_number}</td>
                    <td className="border px-4 py-2">{bill.rental_id}</td>
                    <td className="border px-4 py-2">{bill.user_id}</td>
                    <td className="border px-4 py-2">{bill.previousunits}</td>
                    <td className="border px-4 py-2">{bill.currentunits}</td>
                    <td className="border px-4 py-2">
                      <button
                        onClick={() => {
                          setBillToUpdate(bill);
                          setCurrentUnits(bill.currentunits);
                          setUpdateModalOpen(true);
                        }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                      >
                        Update
                      </button>
                      <button
                        onClick={() => handleDeleteWaterBill(bill.waterbill_id)}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default PropertyWaterBillPage;
