import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";

const AddReservationsBillsPage = () => {
  const [reservations, setReservations] = useState([]);
  const [propertyId, setPropertyId] = useState(
    localStorage.getItem("selectedPropertyId") || ""
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (propertyId) {
      fetchReservations();
    }
  }, [propertyId]);

  const fetchReservations = () => {
    const token = localStorage.getItem("token");

    if (propertyId && token) {
      setLoading(true);
      // Fetch reservations based on propertyId
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/user/reservations/viewAll/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setReservations(response.data.response);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching reservations:", error);
          setLoading(false);
        });
    }
  };

  const handleViewBills = (roomId) => {
    // Set rental ID (room_id) in local storage
    localStorage.setItem("selectedRentalId", roomId);
    // Redirect to the bills page for the specific rental
    navigate("/admin/addpropertybills");
  };

  const handlePropertyIdChange = (event) => {
    const newPropertyId = event.target.value;
    setPropertyId(newPropertyId);
    localStorage.setItem("selectedPropertyId", newPropertyId); // Store in localStorage
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Set bills for tenants</h1>

          {/* Property ID input */}
          <div className="mb-4">
            <label htmlFor="propertyId" className="block font-bold mb-2">
              Enter Property ID:
            </label>
            <input
              type="text"
              id="propertyId"
              value={propertyId}
              onChange={handlePropertyIdChange}
              className="border rounded px-4 py-2 w-full"
              placeholder="Enter Property ID"
            />
            <button
              onClick={fetchReservations}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
            >
              Fetch Reservations
            </button>
          </div>

          {/* Display reservations */}
          {loading ? (
            <p>Loading reservations...</p>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {reservations.map((reservation) => (
                <div
                  key={reservation.room_id}
                  className="bg-white shadow-md rounded-md p-4"
                >
                  <p>
                    <strong>User Name:</strong> {reservation.user_name}
                  </p>
                  <p>
                    <strong>Email:</strong> {reservation.email}
                  </p>
                  <p>
                    <strong>Phone:</strong> {reservation.phone}
                  </p>
                  <p>
                    <strong>Entry Date:</strong> {reservation.entrydate}
                  </p>
                  <p>
                    <strong>House Number:</strong> {reservation.house_no}
                  </p>
                  <p>
                    <strong>Booking Status:</strong>{" "}
                    {reservation.booking_status}
                  </p>
                  <p>
                    <strong>Deposit Paid:</strong> {reservation.deposit_paid}
                  </p>
                  <p>
                    <strong>Payment Status:</strong>{" "}
                    {reservation.payment_status}
                  </p>
                  {/* Button for viewing bills */}
                  <button
                    onClick={() => handleViewBills(reservation.room_id)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
                  >
                    Set Tenant Bills
                  </button>
                </div>
              ))}
            </div>
          )}

          {/* Add button to navigate back and set propertyId to null */}
          <button
            onClick={() => {
              setPropertyId(""); // Set propertyId to null
              localStorage.removeItem("selectedPropertyId");
              navigate(-1); // Navigate back
            }}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
          >
            Go Back
          </button>
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default AddReservationsBillsPage;
