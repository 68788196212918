import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { Oval } from "react-loader-spinner"; // Import loader
import { EmailGuard } from "../App";

const Bank = () => {
  const [banks, setBanks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newBank, setNewBank] = useState({
    bank_code: "",
    bank_name: "",
  });
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  useEffect(() => {
    fetchBanks();
  }, []);

  const fetchBanks = () => {
    const token = localStorage.getItem("token");
    axios
      .get("https://backend.inyumba.co.ke/iNyumba/admin/bank/fetch", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setBanks(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching banks:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBank({ ...newBank, [name]: value });
  };

  const handleAddBank = () => {
    setIsLoading(true); // Set loading state to true
    const token = localStorage.getItem("token");
    const response = axios
      .post("https://backend.inyumba.co.ke/iNyumba/admin/bank/add", newBank, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setIsModalOpen(false);
        setNewBank({ bank_code: "", bank_name: "" });
        fetchBanks();
        setIsLoading(false); // Set loading state to true
        toast.success(response.data.response);
      })
      .catch((error) => {
        console.error("Error adding bank:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleDeleteBank = (bankCode) => {
    setIsLoading(true); // Set loading state to true
    const token = localStorage.getItem("token");
    const response = axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/admin/bank/delete/${bankCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        fetchBanks();
        setIsLoading(false); // Set loading state to true
        toast.success(response.data.response);
      })
      .catch((error) => {
        setIsLoading(false); // Set loading state to true
        console.error("Error deleting bank:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleDownload = (fileType) => {
    const endpoint = `https://backend.inyumba.co.ke/iNyumba/report/banksreports`;

    // Fetch and download file
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { format: fileType },
        responseType: "blob", // Important for file download
      })
      .then((response) => {
        const fileExtension = fileType === "pdf" ? "pdf" : "csv";
        const fileName = `inyumbabankrecords.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Download is successfull");
      })
      .catch((error) => {
        console.error(`Error downloading ${fileType.toUpperCase()}:`, error);
        toast.error(error.response.data.response);
      });
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Banks</h1>
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
          >
            Add Bank
          </button>
          <div className="flex space-x-2">
            <button
              onClick={() => handleDownload("csv")}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm"
            >
              <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
              Download
            </button>
            <button
              onClick={() => handleDownload("pdf")}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
            >
              <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
              Download
            </button>
          </div>
          <table className="w-full">
            <thead>
              <tr>
                <th className="px-4 py-2">Bank Code</th>
                <th className="px-4 py-2">Bank Name</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {banks.map((bank) => (
                <tr key={bank.bank_code}>
                  <td className="border px-4 py-2">{bank.bankcode}</td>
                  <td className="border px-4 py-2">{bank.bankname}</td>
                  <td className="border px-4 py-2">
                    <button
                      onClick={() => handleDeleteBank(bank.bankcode)}
                      disabled={isLoading}
                      className={`${
                        isLoading
                          ? "bg-gray-500"
                          : "bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded mr-2"
                      } text-white py-2 px-4 rounded-md focus:outline-none`}
                    >
                      {isLoading ? (
                        <Oval
                          height={20}
                          width={20}
                          color="white"
                          visible={true}
                          ariaLabel="loading-indicator"
                          secondaryColor="white"
                        />
                      ) : (
                        "Delete"
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {isModalOpen && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
            <div className="bg-white p-6 rounded-lg w-96">
              <h2 className="text-lg font-semibold mb-4">Add Bank</h2>
              <input
                type="text"
                name="bank_code"
                value={newBank.bank_code}
                onChange={handleInputChange}
                placeholder="Bank Code"
                className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
              />
              <input
                type="text"
                name="bank_name"
                value={newBank.bank_name}
                onChange={handleInputChange}
                placeholder="Bank Name"
                className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
              />
              <div className="flex justify-end">
                <button
                  onClick={handleAddBank}
                  disabled={isLoading}
                  className={`${
                    isLoading
                      ? "bg-gray-500"
                      : "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                  } text-white py-2 px-4 rounded-md focus:outline-none`}
                >
                  {isLoading ? (
                    <Oval
                      height={20}
                      width={20}
                      color="white"
                      visible={true}
                      ariaLabel="loading-indicator"
                      secondaryColor="white"
                    />
                  ) : (
                    "Add"
                  )}
                </button>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </AuthGuard>
    </EmailGuard>
  );
};

export default Bank;
