import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFilePdf,
  faFileCsv,
} from "@fortawesome/free-solid-svg-icons";

const AuditLog = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");

  useEffect(() => {
    fetchLogs();
  }, []);

  const fetchLogs = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        "https://backend.inyumba.co.ke/iNyumba/view/auditlogs",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLogs(response.data.response);
    } catch (error) {
      toast.error("Failed to fetch logs");
    } finally {
      setLoading(false);
    }
  };

  const deleteLog = async (logId) => {
    const token = localStorage.getItem("token");
    try {
      await axios.delete(
        `https://backend.inyumba.co.ke/iNyumba/delete/auditlogs/${logId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLogs(logs.filter((log) => log.index !== logId));
      toast.success("Log deleted successfully");
    } catch (error) {
      toast.error("Failed to delete log");
    }
  };

  const handleDownload = (fileType) => {
    const token = localStorage.getItem("token");
    const endpoint = `https://backend.inyumba.co.ke/iNyumba/logsreportsalltime`;

    // Fetch and download file
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { format: fileType },
        responseType: "blob", // Important for file download
      })
      .then((response) => {
        const fileExtension = fileType === "pdf" ? "pdf" : "csv";
        const fileName = `inyumbaauditlogs.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Download is successfull");
      })
      .catch((error) => {
        console.error(`Error downloading ${fileType.toUpperCase()}:`, error);
        toast.error("Error downloading");
      });
  };

  const handleFilteredDownload = (fileType) => {
    const token = localStorage.getItem("token");
    const endpoint = `https://backend.inyumba.co.ke/iNyumba/logsreports`;

    // Fetch and download file
    axios
      .post(
        endpoint,
        { year, month, day }, // JSON body with year and month
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { format: fileType }, // Query parameter for format
          responseType: "blob", // Important for file download
        }
      )
      .then((response) => {
        const fileExtension = fileType === "pdf" ? "pdf" : "csv";
        const fileName = `inyumbaauditlogs_${year}_${month}_${day}.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Download is successful");
      })
      .catch((error) => {
        console.error(`Error downloading ${fileType.toUpperCase()}:`, error);
        toast.error(
          "Error downloading. Possible issues: Year not entered or no records for that particular period"
        );
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="p-6">
          <h1 className="text-2xl font-bold text-center mb-6">Audit Logs</h1>
          {/* Download buttons */}
          <div className="flex justify-between w-full max-w-4xl mb-4">
            <div className="flex flex-col items-start space-y-2">
              {/* Search box for year and month selection */}
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  placeholder="Year(This is a must)"
                  className="border border-gray-300 rounded py-1 px-3 text-sm"
                />
                <input
                  type="number"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  placeholder="Month"
                  className="border border-gray-300 rounded py-1 px-3 text-sm"
                />
                <input
                  type="number"
                  value={day}
                  onChange={(e) => setDay(e.target.value)}
                  placeholder="Day"
                  className="border border-gray-300 rounded py-1 px-3 text-sm"
                />
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => handleFilteredDownload("csv")}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm"
                >
                  <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
                  Download
                </button>
                <button
                  onClick={() => handleFilteredDownload("pdf")}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
                >
                  <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
                  Download
                </button>
              </div>
            </div>

            <div className="flex space-x-2">
              <button
                onClick={() => handleDownload("csv")}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm"
              >
                <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
                Download All records
              </button>
              <button
                onClick={() => handleDownload("pdf")}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
              >
                <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
                Download All records
              </button>
            </div>
          </div>
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="bg-gray-200">
                <th className="py-2 px-4 border-b">Index</th>
                <th className="py-2 px-4 border-b">User</th>
                <th className="py-2 px-4 border-b">IP Address</th>
                <th className="py-2 px-4 border-b">Device</th>
                <th className="py-2 px-4 border-b">Email</th>
                <th className="py-2 px-4 border-b">Description</th>
                <th className="py-2 px-4 border-b">Change Made</th>
                <th className="py-2 px-4 border-b">Action Time</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log) => (
                <tr key={log.index} className="border-b hover:bg-gray-100">
                  <td className="py-2 px-4">{log.index}</td>
                  <td className="py-2 px-4">{log.user}</td>
                  <td className="py-2 px-4">{log.ipAddress}</td>
                  <td className="py-2 px-4">{log.device}</td>
                  <td className="py-2 px-4">{log.email}</td>
                  <td className="py-2 px-4">{log.description}</td>
                  <td className="py-2 px-4">
                    {JSON.stringify(log.changeMade)}
                  </td>
                  <td className="py-2 px-4">
                    {new Date(log.actionTime).toLocaleString()}
                  </td>
                  <td className="py-2 px-4">
                    <button
                      onClick={() => deleteLog(log.index)}
                      className="bg-red-500 text-white py-1 px-2 rounded hover:bg-red-600"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default AuditLog;
