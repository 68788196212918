import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faFilePdf } from "@fortawesome/free-solid-svg-icons";

const HouseHunterSettlements = () => {
  const [huntersettlementsi, setHunterSettlements] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPropertyHunter();
  }, []);

  const fetchPropertyHunter = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        "https://backend.inyumba.co.ke/iNyumba/admin/settlementcheck/househunter",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setHunterSettlements(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching Hunter Settlements:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleDownload = (endpoint, fileType) => {
    const token = localStorage.getItem("token");
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { format: fileType },
        responseType: "blob", // Important for file download
      })
      .then((response) => {
        const fileExtension = fileType === "pdf" ? "pdf" : "csv";
        const fileName = `househuntersettlementreport_${new Date().toISOString()}.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Download is successful");
      })
      .catch((error) => {
        console.log(error);
        console.error(`Error downloading ${fileType.toUpperCase()}:`, error);
        toast.error(error.response.data.response);
      });
  };

  const handleDelete = (settlementId) => {
    const token = localStorage.getItem("token");
    if (window.confirm("Are you sure you want to delete this settlement?")) {
      axios
        .delete(
          `https://backend.inyumba.co.ke/iNyumba/admin/settlementcheck/deletepropertyhuntersettlement/${settlementId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          toast.success("Settlement deleted successfully");
          // Optionally, refresh the list after deletion
          setHunterSettlements((prevSettlements) =>
            prevSettlements.filter(
              (settlement) => settlement.settlement_id !== settlementId
            )
          );
        })
        .catch((error) => {
          console.error("Error deleting settlement:", error);
          toast.error("Failed to delete the settlement");
        });
    }
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">
            Property Hunter Settlements
          </h1>

          {/* Download buttons */}
          <div className="flex justify-between w-full max-w-4xl mb-4 mt-4">
            <div className="flex space-x-2">
              <button
                onClick={() =>
                  handleDownload(
                    "https://backend.inyumba.co.ke/iNyumba/admin/settlementreport/propertyhuntersettlementme",
                    "csv"
                  )
                }
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm"
              >
                <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
                CSV
              </button>
              <button
                onClick={() =>
                  handleDownload(
                    "https://backend.inyumba.co.ke/iNyumba/admin/settlementreport/propertyhuntersettlementme",
                    "pdf"
                  )
                }
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
              >
                <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
                PDF
              </button>
            </div>
          </div>
          <table className="w-full">
            <thead>
              <tr>
                <th className="px-4 py-2">Settlement Id</th>
                <th className="px-4 py-2">ID Number</th>
                <th className="px-4 py-2">Total Amount Paid</th>
                <th className="px-4 py-2">Our Reference</th>
                <th className="px-4 py-2">Transaction Reference</th>
                <th className="px-4 py-2">Status</th>
                <th className="px-4 py-2">Created On</th>
              </tr>
            </thead>
            <tbody>
              {huntersettlementsi.map((huntersettlementsi) => (
                <tr key={huntersettlementsi.settlement_id}>
                  <td className="border px-4 py-2">
                    {huntersettlementsi.settlement_id}
                  </td>
                  <td className="border px-4 py-2">
                    {huntersettlementsi.user_id}
                  </td>
                  <td className="border px-4 py-2">
                    {huntersettlementsi.total_amount_paid}
                  </td>
                  <td className="border px-4 py-2">
                    {huntersettlementsi.reference}
                  </td>
                  <td className="border px-4 py-2">
                    {huntersettlementsi.paymentreference}
                  </td>
                  <td className="border px-4 py-2">
                    {huntersettlementsi.status}
                  </td>
                  <td className="border px-4 py-2">
                    {new Date(huntersettlementsi.created_at).toLocaleString()}
                  </td>
                  {/* Add the delete button here */}
                  <td className="border px-4 py-2">
                    <button
                      onClick={() =>
                        handleDelete(huntersettlementsi.settlement_id)
                      }
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default HouseHunterSettlements;
