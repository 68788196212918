import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFilePdf,
  faFileCsv,
} from "@fortawesome/free-solid-svg-icons";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";
import { toast } from "react-toastify";

const RecordsPage = () => {
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [totalCommission, setTotalCommission] = useState(0);
  const [tax, setTax] = useState(0);
  const [actualWithdrawal, setActualWithdrawal] = useState(0);
  const [ourMoney, setOurMoney] = useState(0);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [balance, setBalance] = useState(null);
  const [taxCash, setTaxCash] = useState(0); // Define taxCash as state
  const token = localStorage.getItem("token");

  const [amountup, setAmountUp] = useState("");
  const [waterbill, setWaterbill] = useState("");
  const [rentbill, setRentbill] = useState("");
  const [trashbill, setTrashbill] = useState("");
  const [commission, setCommission] = useState("");
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [recordToUpdate, setRecordToUpdate] = useState(null);

  useEffect(() => {
    if (token) {
      // Fetch records
      axios
        .get(`https://backend.inyumba.co.ke/iNyumba/viewrecordsrentadmin`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const fetchedRecords = response.data.response;
          // setRecords(fetchedRecords);

          // Filter records to only include those with a 'recordstatus' of 'running'
          const runningRecords = fetchedRecords.filter(
            (record) => record.recordsstatus === "running"
          );

          // Calculate total commission based on running records
          const totalCommission = runningRecords.reduce(
            (sum, record) => sum + parseFloat(record.inyumbacommision),
            0
          );
          setTotalCommission(totalCommission);

          const taxcash = totalCommission * 0.3;
          setTax(taxcash);
          setTaxCash(taxcash); // Ensure taxCash is set
          setOurMoney(totalCommission * 0.7);

          setRecords(fetchedRecords);
        })
        .catch((error) => {
          console.error("Error fetching records:", error);
        });

      // Fetch balance
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/admin/househunter/fetchinyumbabalance`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data && response.data.response.length > 0) {
            const fetchedBalance = response.data.response[0].balance;
            setBalance(fetchedBalance);
          }
        })
        .catch((error) => {
          console.error("Error fetching balance:", error);
          toast.error(error.response.data.response);
        });
    }
  }, [token]);

  useEffect(() => {
    if (balance !== null && taxCash !== null) {
      // Calculate actual withdrawal: balance - (tax + 65)
      const calculatedWithdrawal = balance - (taxCash + 65);
      setActualWithdrawal(calculatedWithdrawal);
    }
  }, [balance, taxCash]); // Add balance and taxCash as dependencies

  const handleDownload = (fileType) => {
    const endpoint = `https://backend.inyumba.co.ke/iNyumba/reportsdownloadadmin`;

    // Fetch and download file
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { format: fileType },
        responseType: "blob", // Important for file download
      })
      .then((response) => {
        const fileExtension = fileType === "pdf" ? "pdf" : "csv";
        const fileName = `inyumbapaidrentrecords.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Download is successfull");
      })
      .catch((error) => {
        console.error(`Error downloading ${fileType.toUpperCase()}:`, error);
        toast.error(error.response.data.response);
      });
  };

  const handleFilteredDownload = (fileType) => {
    const endpoint = `https://backend.inyumba.co.ke/iNyumba/reportsdownloadbymonthandyear`;

    // Fetch and download file
    axios
      .post(
        endpoint,
        { year, month }, // JSON body with year and month
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { format: fileType }, // Query parameter for format
          responseType: "blob", // Important for file download
        }
      )
      .then((response) => {
        const fileExtension = fileType === "pdf" ? "pdf" : "csv";
        const fileName = `inyumbapaidrentrecords_${year}_${month}.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Download is successful");
      })
      .catch((error) => {
        console.error(`Error downloading ${fileType.toUpperCase()}:`, error);
        toast.error(error.response.data.response);
      });
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const updateOneRecord = (records_id) => {
    const token = localStorage.getItem("token");
    axios
      .patch(
        `https://backend.inyumba.co.ke/iNyumba/updateonerecordadmin/${records_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
      })
      .catch((error) => {
        console.error("Error updating record status:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleDeleteRecord = (recordIdsDel) => {
    const token = localStorage.getItem("token");
    axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/deleterecords/${recordIdsDel}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        toast.success("Record deleted successfully.");
      })
      .catch((error) => {
        console.error("Error deleting Record:", error);
        toast.error("Failed to delete Record.");
      });
  };

  const updateAllRecords = () => {
    const token = localStorage.getItem("token");
    axios
      .patch(
        `https://backend.inyumba.co.ke/iNyumba/updateallrecordsadmin`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
      })
      .catch((error) => {
        console.error("Error updating records status:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleDownloadRunning = (fileType) => {
    const endpoint = `https://backend.inyumba.co.ke/iNyumba/reportsdownloadrunning`;

    // Fetch and download file
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { format: fileType },
        responseType: "blob", // Important for file download
      })
      .then((response) => {
        const fileExtension = fileType === "pdf" ? "pdf" : "csv";
        const fileName = `inyumbapaidrentrecords.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Download is successfull");
      })
      .catch((error) => {
        console.error(`Error downloading ${fileType.toUpperCase()}:`, error);
        toast.error(error.response.data.response);
      });
  };

  const handleOpenUpdateModal = (record) => {
    setRecordToUpdate(record); // Set the selected record to update
    setAmountUp(record.total_amount_paid || ""); // Set initial value for amount
    setWaterbill(record.water_bill || ""); // Set initial value for water bill
    setRentbill(record.rent_bill || ""); // Set initial value for rent bill
    setTrashbill(record.trash_bill || ""); // Set initial value for trash bill
    setCommission(record.inyumbacommision || ""); // Set initial value for commission
    setUpdateModalOpen(true); // Open the modal
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setUpdateModalOpen(false);
    setRecordToUpdate(null); // Clear the selected record
  };

  const handleUpdateRecordOne = (e, recordIdUse) => {
    e.preventDefault(); // Prevent default form submission
    const token = localStorage.getItem("token");

    const data = {
      amountpaid: amountup,
      waterbill: waterbill,
      rentbill: rentbill,
      trashbill: trashbill,
      commission: commission,
    };

    axios
      .patch(
        `https://backend.inyumba.co.ke/iNyumba/updaterecordsfigures/${recordIdUse}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        toast.success("Record updated successfully.");
        setUpdateModalOpen(false); // Close the update modal after success
        navigate(-1);
        // Reset form values after action is completed
        setAmountUp("");
        setWaterbill("");
        setRentbill("");
        setTrashbill("");
        setCommission("");
      })
      .catch((error) => {
        console.error("Error updating Record:", error);
        toast.error("Failed to update Record.");
        setUpdateModalOpen(false); // Close the update modal after failure
      });
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="flex flex-col items-center">
          {/* Go back button */}
          <button
            onClick={() => navigate(-1)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
            Back
          </button>
          {/* Flex boxes for total commission and tax */}
          <div className="w-full max-w-4xl flex justify-between mb-4">
            <div className="bg-gray-100 p-4 rounded shadow-md">
              <h2 className="text-xl font-semibold">Running Bal</h2>
              <p className="text-2xl">
                {balance !== null ? `Ksh ${balance}` : "0"}
              </p>
            </div>
            <div className="bg-gray-100 p-4 rounded shadow-md">
              <h2 className="text-xl font-semibold">Total Commission</h2>
              <p className="text-2xl">{totalCommission.toFixed(2)}</p>
            </div>

            <div className="bg-gray-100 p-4 rounded shadow-md">
              <h2 className="text-xl font-semibold">Our Money</h2>
              <p className="text-2xl">{ourMoney.toFixed(2)}</p>
            </div>
            <div className="bg-gray-100 p-4 rounded shadow-md">
              <h2 className="text-xl font-semibold">Withdraw</h2>
              <p className="text-2xl">
                {actualWithdrawal !== null
                  ? `Ksh ${actualWithdrawal.toFixed(2)}`
                  : "0"}
              </p>
            </div>

            <div className="bg-gray-100 p-4 rounded shadow-md">
              <h2 className="text-xl font-semibold">KRA Tax (30%)</h2>
              <p className="text-2xl">{tax.toFixed(2)}</p>
            </div>
          </div>
          {/* Download buttons */}
          <div className="flex justify-between w-full max-w-4xl mb-4">
            <div className="flex flex-col items-start space-y-2">
              {/* Search box for year and month selection */}
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  placeholder="Year(This is a must)"
                  className="border border-gray-300 rounded py-1 px-3 text-sm"
                />
                <input
                  type="number"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  placeholder="Month"
                  className="border border-gray-300 rounded py-1 px-3 text-sm"
                />
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => handleFilteredDownload("csv")}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm"
                >
                  <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
                  Download
                </button>
                <button
                  onClick={() => handleFilteredDownload("pdf")}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
                >
                  <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
                  Download
                </button>
              </div>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => handleDownload("csv")}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm"
              >
                <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
                Download All records
              </button>
              <button
                onClick={() => handleDownload("pdf")}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
              >
                <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
                Download All records
              </button>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => handleDownloadRunning("csv")}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm"
              >
                <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
                Download Running records
              </button>
              <button
                onClick={() => handleDownloadRunning("pdf")}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
              >
                <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
                Download Running records
              </button>
            </div>

            <div className="flex space-x-2">
              <button
                onClick={() => updateAllRecords()}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded mr-2"
              >
                Update All Records Status
              </button>
            </div>
          </div>
          {/* Records List */}
          <div className="w-full max-w-4xl">
            <h1 className="text-3xl font-semibold mb-4">Records</h1>
            {records.length > 0 ? (
              <table className="table-auto w-full mb-4">
                <thead>
                  <tr>
                    <th className="px-4 py-2">Record ID</th>
                    <th className="px-4 py-2">User ID</th>
                    <th className="px-4 py-2">Name</th>
                    <th className="px-4 py-2">Phone</th>
                    <th className="px-4 py-2">Email</th>
                    <th className="px-4 py-2">Property ID</th>
                    <th className="px-4 py-2">Rental ID</th>
                    <th className="px-4 py-2">House Number</th>
                    <th className="px-4 py-2">Total Amount Paid</th>
                    <th className="px-4 py-2">Water Bill</th>
                    <th className="px-4 py-2">Rent Bill</th>
                    <th className="px-4 py-2">Trash Bill</th>
                    <th className="px-4 py-2">Commision</th>
                    <th className="px-4 py-2">RecordsStatus</th>
                    <th className="px-4 py-2">Created At</th>
                    <th className="px-4 py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((record) => (
                    <tr key={record.record_id}>
                      <td className="border px-4 py-2">{record.record_id}</td>
                      <td className="border px-4 py-2">{record.user_id}</td>
                      <td className="border px-4 py-2">{record.user_name}</td>
                      <td className="border px-4 py-2">{record.phone}</td>
                      <td className="border px-4 py-2">{record.email}</td>
                      <td className="border px-4 py-2">{record.property_id}</td>
                      <td className="border px-4 py-2">{record.rental_id}</td>
                      <td className="border px-4 py-2">
                        {record.house_number}
                      </td>
                      <td className="border px-4 py-2">
                        {record.total_amount_paid}
                      </td>
                      <td className="border px-4 py-2">{record.water_bill}</td>
                      <td className="border px-4 py-2">{record.rent_bill}</td>
                      <td className="border px-4 py-2">{record.trash_bill}</td>
                      <td className="border px-4 py-2">
                        {record.inyumbacommision}
                      </td>
                      <td className="border px-4 py-2">
                        {record.recordsstatus}
                      </td>
                      <td className="border px-4 py-2">
                        {new Date(record.created_at).toLocaleString()}
                      </td>
                      <td className="border px-8 py-4">
                        <button
                          onClick={() => updateOneRecord(record.record_id)}
                          className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded mr-2"
                        >
                          Update Records Status
                        </button>
                        <button
                          onClick={() => handleDeleteRecord(record.record_id)}
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded mr-2"
                        >
                          Delete
                        </button>
                        <button
                          onClick={() => handleOpenUpdateModal(record)}
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2"
                        >
                          Update
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No records found.</p>
            )}
          </div>

          {updateModalOpen && recordToUpdate && (
            <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-md w-96">
                <h2 className="text-2xl font-semibold mb-4">Update Record</h2>
                <form
                  onSubmit={(e) =>
                    handleUpdateRecordOne(e, recordToUpdate.record_id)
                  }
                >
                  {/* Input fields for updating the record */}
                  <input
                    type="number"
                    value={amountup || ""}
                    onChange={(e) => setAmountUp(e.target.value)}
                    placeholder="Amount Paid"
                    className="border border-gray-300 rounded py-1 px-3 text-sm mb-2 w-full"
                  />
                  <input
                    type="number"
                    value={waterbill || ""}
                    onChange={(e) => setWaterbill(e.target.value)}
                    placeholder="Water Bill"
                    className="border border-gray-300 rounded py-1 px-3 text-sm mb-2 w-full"
                  />
                  <input
                    type="number"
                    value={rentbill || ""}
                    onChange={(e) => setRentbill(e.target.value)}
                    placeholder="Rent Bill"
                    className="border border-gray-300 rounded py-1 px-3 text-sm mb-2 w-full"
                  />
                  <input
                    type="number"
                    value={trashbill || ""}
                    onChange={(e) => setTrashbill(e.target.value)}
                    placeholder="Trash Bill"
                    className="border border-gray-300 rounded py-1 px-3 text-sm mb-2 w-full"
                  />
                  <input
                    type="number"
                    value={commission || ""}
                    onChange={(e) => setCommission(e.target.value)}
                    placeholder="Commission"
                    className="border border-gray-300 rounded py-1 px-3 text-sm mb-4 w-full"
                  />

                  <div className="flex justify-end space-x-2">
                    <button
                      type="button"
                      onClick={handleCloseModal}
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-4 rounded"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default RecordsPage;
