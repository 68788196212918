import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner"; // Import loader

const ServiceChargePage = () => {
  const [serviceCharges, setServiceCharges] = useState([]);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [modalData, setModalData] = useState({
    property_id: "",
    servicefee: "",
  });

  const [isLoading, setIsLoading] = useState(false); // Add loading state

  useEffect(() => {
    fetchServiceCharges();
  }, []);

  const fetchServiceCharges = () => {
    const token = localStorage.getItem("token");
    axios
      .get("https://backend.inyumba.co.ke/iNyumba/serviceratecharge/fetchall", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setServiceCharges(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching service charges:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleUpdateServiceCharge = () => {
    setIsLoading(true); // Set loading state to true
    const token = localStorage.getItem("token");
    const { servicefee } = modalData;
    axios
      .patch(
        `https://backend.inyumba.co.ke/iNyumba/serviceratecharge/update/${selectedPropertyId}`,
        { servicefee },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setIsModalOpen(false);
        fetchServiceCharges();
        toast.success(response.data.response);
        setIsLoading(false); // Set loading state to false
      })
      .catch((error) => {
        console.error("Error updating service charge:", error);
        toast.error(error.response.data.response);
        setIsLoading(false); // Set loading state to false
      });
  };

  const handleDeleteServiceCharge = (propertyId) => {
    const token = localStorage.getItem("token");
    axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/serviceratecharge/delete/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        fetchServiceCharges();
        toast.success(response.data.response);
      })
      .catch((error) => {
        console.error("Error deleting service charge:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleAddServiceCharge = () => {
    const token = localStorage.getItem("token");
    const { property_id, servicefee } = modalData;
    axios
      .post(
        "https://backend.inyumba.co.ke/iNyumba/serviceratecharge/add",
        { property_id, servicefee },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setIsModalOpen(false);
        fetchServiceCharges();
        toast.success(response.data.response);
      })
      .catch((error) => {
        console.error("Error adding service charge:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleOpenUpdateModal = (serviceCharge) => {
    setSelectedPropertyId(serviceCharge.property_id);
    setModalData({
      property_id: serviceCharge.property_id, // Added to modalData
      servicefee: serviceCharge.servicefee,
    });
    setModalAction("Update"); // Track the action
    setIsModalOpen(true);
  };

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setModalData({ ...modalData, [name]: value });
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Service Rate Charge</h1>
          <button
            onClick={() => {
              setModalAction("Add");
              setModalData({ property_id: "", servicefee: "" });
              setIsModalOpen(true);
            }}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Add Service Charge
          </button>
          <table className="mt-4 w-full">
            <thead>
              <tr>
                <th className="px-4 py-2">PropertyId</th>
                <th className="px-4 py-2">Service Charge</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {serviceCharges.map((serviceCharge) => (
                <tr key={serviceCharge.property_id}>
                  <td className="border px-4 py-2">
                    {serviceCharge.property_id}
                  </td>
                  <td className="border px-4 py-2">
                    {serviceCharge.servicefee}
                  </td>
                  <td className="border px-4 py-2">
                    <button
                      onClick={() => handleOpenUpdateModal(serviceCharge)}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                    >
                      Update
                    </button>
                    <button
                      onClick={() =>
                        handleDeleteServiceCharge(serviceCharge.property_id)
                      }
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {isModalOpen && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
            <div className="bg-white p-6 rounded-lg w-96">
              {modalAction === "Update" ? (
                <>
                  <h2 className="text-lg font-semibold mb-4">
                    Update Service Charge
                  </h2>
                  <input
                    type="text"
                    name="servicefee"
                    value={modalData.servicefee}
                    onChange={handleModalInputChange}
                    placeholder="Service Charge"
                    className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                  />
                  <div className="flex justify-end">
                    <button
                      onClick={handleUpdateServiceCharge}
                      disabled={isLoading}
                      className={`${
                        isLoading
                          ? "bg-gray-500"
                          : "bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                      } text-white py-2 px-4 rounded-md focus:outline-none`}
                    >
                      {isLoading ? (
                        <Oval
                          height={20}
                          width={20}
                          color="white"
                          visible={true}
                          ariaLabel="loading-indicator"
                          secondaryColor="white"
                        />
                      ) : (
                        "Update"
                      )}
                    </button>

                    <button
                      onClick={() => setIsModalOpen(false)}
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <h2 className="text-lg font-semibold mb-4">
                    Add Service Charge
                  </h2>
                  <input
                    type="text"
                    name="property_id"
                    value={modalData.property_id}
                    onChange={handleModalInputChange}
                    placeholder="Property ID"
                    className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                  />
                  <input
                    type="text"
                    name="servicefee"
                    value={modalData.servicefee}
                    onChange={handleModalInputChange}
                    placeholder="Service Charge"
                    className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                  />
                  <div className="flex justify-end">
                    <button
                      onClick={handleAddServiceCharge}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                    >
                      Add
                    </button>
                    <button
                      onClick={() => setIsModalOpen(false)}
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </AuthGuard>
    </EmailGuard>
  );
};

export default ServiceChargePage;
