import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { Oval } from "react-loader-spinner"; // Import loader
import { EmailGuard } from "../App";

const AllTrashFeesPage = () => {
  const [trashFees, setTrashFees] = useState([]); // State to store fetched trash fees
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoading(true); // Start loading before making the API call
    axios
      .get("https://backend.inyumba.co.ke/iNyumba/trashfee/fetchalltrashfee", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.response) {
          setTrashFees(response.data.response); // Set trash fees data if available
          toast.success("Trash fees data fetched successfully!");
        } else {
          setTrashFees([]); // Clear the trash fees state if no data
        }
        setIsLoading(false); // Stop loading
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.response || "Failed to fetch trash fees."
        );
        console.error("Error fetching trash fees:", error);
        setIsLoading(false); // Stop loading on error
      });
  }, []); // Fetch trash fees once on component mount

  const handleDeleteTrashFee = (propertyId) => {
    const token = localStorage.getItem("token");
    setIsLoading(true); // Start loading before making the delete request
    axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/trashfee/deletetrashfee/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response); // Show success message
        setTrashFees(trashFees.filter((fee) => fee.propertyid !== propertyId)); // Remove deleted trash fee from the list
        setIsLoading(false); // Stop loading
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.response || "Failed to delete trash fee."
        );
        console.error("Error deleting trash fee:", error);
        setIsLoading(false); // Stop loading on error
      });
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Trash Fees</h1>

          {/* Loading Spinner */}
          {isLoading && (
            <div className="flex justify-center items-center mb-4">
              <Oval height={40} width={40} color="#4e73df" visible={true} />
            </div>
          )}

          {/* Trash Fees Table */}
          {!isLoading && trashFees.length > 0 && (
            <table className="table-auto w-full border-collapse border border-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 border-b">Property ID</th>
                  <th className="px-4 py-2 border-b">Trash Fee Amount</th>
                  <th className="px-4 py-2 border-b">Actions</th>
                </tr>
              </thead>
              <tbody>
                {trashFees.map((trashFee) => (
                  <tr key={trashFee.propertyid}>
                    <td className="px-4 py-2 border-b">
                      {trashFee.propertyid}
                    </td>
                    <td className="px-4 py-2 border-b">{trashFee.feeamount}</td>
                    <td className="px-4 py-2 border-b">
                      <button
                        onClick={() =>
                          handleDeleteTrashFee(trashFee.propertyid)
                        }
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {/* If no trash fees are found */}
          {!isLoading && trashFees.length === 0 && (
            <p>No trash fees available.</p>
          )}
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default AllTrashFeesPage;
