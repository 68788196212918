import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner"; // Import loader
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";

const TransactionsViewPage = () => {
  const [propertyId, setPropertyId] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [transactionIds, setTransactionIds] = useState("");
  const [runningBalance, setRunningBalance] = useState("");
  const [channel, setChannel] = useState("");
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [postModalOpen, setPostModalOpen] = useState(false);
  const [transactionToUpdate, setTransactionToUpdate] = useState(null);

  const fetchTransactions = () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/alltransactionsview/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setTransactions(response.data.response);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
        toast.error(
          error.response?.data?.response || "Error fetching transactions"
        );
        setIsLoading(false);
      });
  };

  const handleDeleteTransaction = (transactionId) => {
    const token = localStorage.getItem("token");
    axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/transactionscontroller/deletetransaction/${transactionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        toast.success("Transaction deleted successfully.");
        fetchTransactions();
      })
      .catch((error) => {
        console.error("Error deleting transaction:", error);
        toast.error("Failed to delete transaction.");
      });
  };

  const handleUpdateTransaction = (transactionId) => {
    const token = localStorage.getItem("token");
    const data = {
      amount: amount,
      runningbalance: runningBalance,
    };

    axios
      .patch(
        `https://backend.inyumba.co.ke/iNyumba/transactionscontroller/updatetransaction/${transactionId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        toast.success("Transaction updated successfully.");
        fetchTransactions();
        setUpdateModalOpen(false); // Close the update modal after success
        // Reset form values after action is completed
        setAmount(null);
        setRunningBalance(null);
      })
      .catch((error) => {
        console.error("Error updating transaction:", error);
        toast.error("Failed to update transaction.");
        setUpdateModalOpen(false); // Close the update modal after success
        // Reset form values after action is completed
        setAmount(null);
        setRunningBalance(null);
      });
  };

  const handlePostTransaction = () => {
    const token = localStorage.getItem("token");
    const data = {
      reference: channel,
      amount: amount,
      runningbalance: runningBalance,
      transactionid: transactionIds,
    };

    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/transactionscontroller/posttransaction/${propertyId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        toast.success("Transaction posted successfully.");
        fetchTransactions();
        setPostModalOpen(false); // Close the post modal after success
        // Reset form values after action is completed
        setChannel(null);
        setAmount(null);
        setRunningBalance(null);
        setTransactionIds(null);
      })
      .catch((error) => {
        console.error("Error posting transaction:", error);
        toast.error("Failed to post transaction.");
        setPostModalOpen(false); // Close the post modal after success
        // Reset form values after action is completed
        setChannel(null);
        setAmount(null);
        setRunningBalance(null);
        setTransactionIds(null);
      });
  };

  const handleDownloadReport = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/reportsdownloadtransactions/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `transactions_report_${propertyId}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading report:", error);
        toast.error("Failed to download report");
      });
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Transactions View</h1>
          <div className="flex mb-4">
            <input
              type="text"
              value={propertyId}
              onChange={(e) => setPropertyId(e.target.value)}
              placeholder="Enter Property ID"
              className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
            />
            <button
              onClick={fetchTransactions}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
            >
              Search
            </button>
            {/* Download Button */}
            <button
              onClick={handleDownloadReport}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-2"
              disabled={!propertyId}
            >
              Download Report
            </button>
            {/* Add Transaction Button */}
            <button
              onClick={() => setPostModalOpen(true)}
              className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded ml-2"
            >
              Add Transaction
            </button>
          </div>

          {/* Post Transaction Modal */}
          {postModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <h2 className="text-2xl">Post Transaction</h2>
                <input
                  type="text"
                  value={channel}
                  onChange={(e) => setChannel(e.target.value)}
                  placeholder="Enter Channel"
                  className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                />
                <input
                  type="text"
                  value={transactionIds}
                  onChange={(e) => setTransactionIds(e.target.value)}
                  placeholder="Enter Transaction Id"
                  className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                />
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Enter Amount"
                  className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                />
                <input
                  type="number"
                  value={runningBalance}
                  onChange={(e) => setRunningBalance(e.target.value)}
                  placeholder="Enter Running Balance"
                  className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                />
                <div className="modal-footer">
                  <button
                    onClick={handlePostTransaction}
                    className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
                    disabled={!channel || !amount || !runningBalance}
                  >
                    Add Transaction
                  </button>
                  <button
                    onClick={() => setPostModalOpen(false)}
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded ml-2"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Update Transaction Modal */}
          {updateModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <h2 className="text-2xl">Update Transaction</h2>
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Enter Amount"
                  className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                />
                <input
                  type="number"
                  value={runningBalance}
                  onChange={(e) => setRunningBalance(e.target.value)}
                  placeholder="Enter Running Balance"
                  className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                />
                <div className="modal-footer">
                  <button
                    onClick={() =>
                      handleUpdateTransaction(
                        transactionToUpdate.transaction_id
                      )
                    }
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    disabled={!amount || !runningBalance}
                  >
                    Update Transaction
                  </button>
                  <button
                    onClick={() => setUpdateModalOpen(false)}
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded ml-2"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          {isLoading ? (
            <div className="flex justify-center">
              <Oval
                height={40}
                width={40}
                color="blue"
                ariaLabel="loading-indicator"
              />
            </div>
          ) : (
            <table className="mt-4 w-full">
              <thead>
                <tr>
                  <th className="px-4 py-2">Date of Transaction</th>
                  <th className="px-4 py-2">Transaction ID</th>
                  <th className="px-4 py-2">Property ID</th>
                  <th className="px-4 py-2">Channel</th>
                  <th className="px-4 py-2">Amount</th>
                  <th className="px-4 py-2">Running Balance</th>
                  <th className="px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction) => (
                  <tr key={transaction.transaction_id}>
                    <td className="border px-4 py-2">
                      {transaction.created_at}
                    </td>
                    <td className="border px-4 py-2">
                      {transaction.transaction_id}
                    </td>
                    <td className="border px-4 py-2">
                      {transaction.propertyid}
                    </td>
                    <td className="border px-4 py-2">
                      {transaction.reference}
                    </td>
                    <td className="border px-4 py-2">{transaction.amount}</td>
                    <td className="border px-4 py-2">
                      {transaction.running_balance}
                    </td>
                    <td className="border px-4 py-2">
                      <button
                        onClick={() => {
                          setTransactionToUpdate(transaction);
                          setAmount(transaction.amount);
                          setRunningBalance(transaction.running_balance);
                          setUpdateModalOpen(true);
                        }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                      >
                        Update
                      </button>
                      <button
                        onClick={() =>
                          handleDeleteTransaction(transaction.transaction_id)
                        }
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default TransactionsViewPage;
