import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";

const ViewVacatePage = () => {
  const [vacateData, setVacateData] = useState([]);
  const [userDetails, setUserDetails] = useState("");

  useEffect(() => {
    if (userDetails) {
      fetchVacateData();
    }
  }, [userDetails]);

  const fetchVacateData = () => {
    const token = localStorage.getItem("token");

    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/vacatinghouse/users/${userDetails}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setVacateData(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching vacate data:", error);
        toast.error("Error fetching vacate data.");
      });
  };

  const handleInputChange = (e) => {
    setUserDetails(e.target.value);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchVacateData();
    }
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-8">View Vacate Page</h1>
          <div className="mb-4 flex items-center">
            {/* Input field for user details */}
            <input
              type="text"
              value={userDetails}
              onChange={handleInputChange}
              onKeyPress={handleEnterKeyPress}
              placeholder="Enter User Details and press Enter..."
              className="border border-gray-300 rounded-md py-2 px-4 w-64 focus:outline-none focus:border-blue-500 mr-2"
            />
            {/* Button for fetching vacate data */}
            <button
              onClick={fetchVacateData}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
            >
              Fetch Vacate Data
            </button>
          </div>

          {vacateData.length > 0 ? (
            vacateData.map((vacateRecord, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-lg overflow-hidden mb-8"
              >
                <div className="p-6">
                  <p className="text-xl font-semibold mb-4">
                    Vacate Information
                  </p>
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <p className="text-lg">
                        <span className="font-semibold">
                          Total Amount for Repairs:
                        </span>{" "}
                        {vacateRecord.totalamountrepairs}
                      </p>
                      <p className="text-lg">
                        <span className="font-semibold">Service Fee:</span>{" "}
                        {vacateRecord.servicefee}
                      </p>
                      <p className="text-lg">
                        <span className="font-semibold">Total Fee:</span>{" "}
                        {vacateRecord.totalfee}
                      </p>
                    </div>
                    <div>
                      <p className="text-lg">
                        <span className="font-semibold">Repair Charges:</span>{" "}
                        {vacateRecord.repaircharges}
                      </p>
                      <p className="text-lg">
                        <span className="font-semibold">Landlord Status:</span>{" "}
                        {vacateRecord.completestatus}
                      </p>
                      <p className="text-lg">
                        <span className="font-semibold">
                          Your Vacate Status:
                        </span>{" "}
                        {vacateRecord.vacatingstatus}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Loading vacate information...</p>
          )}
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default ViewVacatePage;
