import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { Oval } from "react-loader-spinner"; // Import loader
import { EmailGuard } from "../App";

const WaterUnitsPage = () => {
  const [waterUnits, setWaterUnits] = useState({
    units: null,
    amount: null,
    water_unit_id: null,
  }); // State for water units as an object
  const [showAddModal, setShowAddModal] = useState(false); // State for showing add modal
  const [units, setUnits] = useState(null); // State for units in modal
  const [amount, setAmount] = useState(null); // State for amount in modal
  const [propertyId, setPropertyId] = useState(""); // State for propertyId input
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (propertyId) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/waterunits/fetchonewaterunit/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const waterUnitsData = response.data.response;

          if (waterUnitsData.length > 0) {
            const { units, amount, water_unit_id } = waterUnitsData[0];
            setWaterUnits({ units, amount, water_unit_id });
          } else {
            // Reset waterUnits if no record is found
            setWaterUnits({
              units: null,
              amount: null,
              water_unit_id: null,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching water units:", error);
        });
    }
  }, [propertyId]); // Trigger the effect whenever propertyId changes

  const handleAddWaterUnits = () => {
    setIsLoading(true); // Set loading state to true
    // Create FormData object
    const formData = new FormData();
    formData.append("units", units);
    formData.append("amount", amount);

    const token = localStorage.getItem("token");

    // Send POST request to add water units
    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/waterunits/postwaterunits/${propertyId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
        setIsLoading(false); // Set loading state to false
        setShowAddModal(false); // Close the modal after adding water units
      })
      .catch((error) => {
        toast.error(error.response.data.response);
        console.error("Error adding water units:", error);
      });
  };

  const handleUpdateWaterUnits = () => {
    localStorage.setItem("waterUnits", JSON.stringify(waterUnits));
    navigate("/admin/waterunitsupdate");
  };

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Water Units</h1>

          <div className="mb-4">
            <label htmlFor="propertyId" className="block text-sm font-semibold">
              Enter Property ID:
            </label>
            <input
              type="text"
              id="propertyId"
              className="w-full border rounded-md py-2 px-3"
              value={propertyId}
              onChange={(e) => setPropertyId(e.target.value)} // Update the propertyId state
              placeholder="Enter property ID"
            />
          </div>

          <div>
            <p>
              If you already have water units just update, don't add another one
            </p>
            <p>Current Water Units: {waterUnits.units || "No record"}</p>
            <p>Current Water Amount: {waterUnits.amount || "No record"}</p>

            {!waterUnits.units ? (
              // Show Add button if no water units exist
              <button
                onClick={() => setShowAddModal(true)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
                disabled={!propertyId} // Disable if no propertyId is entered
              >
                Add Water Units
              </button>
            ) : (
              // Show Update button if water units exist
              <button
                onClick={handleUpdateWaterUnits}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 ml-2"
                disabled={!propertyId} // Disable if no propertyId is entered
              >
                Update Water Units
              </button>
            )}

            <button
              onClick={handleBack}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mt-4 ml-2"
            >
              Back
            </button>
          </div>

          {/* Modal for adding water units */}
          <Modal
            isOpen={showAddModal}
            onRequestClose={() => setShowAddModal(false)}
            className="modal"
          >
            <div className="bg-white w-1/3 p-6 rounded-lg">
              <h2 className="text-2xl font-semibold mb-4">Add Water Units</h2>
              <p>
                If you already have water units just update, don't add another
                one
              </p>
              <div className="mb-4">
                <label htmlFor="units" className="block text-sm font-semibold">
                  Units:
                </label>
                <input
                  type="number"
                  id="units"
                  className="w-full border rounded-md py-2 px-3"
                  value={units}
                  onChange={(e) => setUnits(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="amount" className="block text-sm font-semibold">
                  Amount:
                </label>
                <input
                  type="number"
                  id="amount"
                  className="w-full border rounded-md py-2 px-3"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="flex justify-end">
                <button
                  onClick={handleAddWaterUnits}
                  disabled={!units || !amount || isLoading} // Disable if units, amount, or loading
                  className={`${
                    isLoading
                      ? "bg-gray-500"
                      : "group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  } text-white py-2 px-4 rounded-md focus:outline-none`}
                >
                  {isLoading ? (
                    <Oval
                      height={20}
                      width={20}
                      color="white"
                      visible={true}
                      ariaLabel="loading-indicator"
                      secondaryColor="white"
                    />
                  ) : (
                    "Add"
                  )}
                </button>

                <button
                  onClick={() => setShowAddModal(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>

          <ToastContainer />
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default WaterUnitsPage;
