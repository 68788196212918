import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileCsv } from "@fortawesome/free-solid-svg-icons";

const PropertyPage = () => {
  const [properties, setProperties] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [originalProperties, setOriginalProperties] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [coordinates, setCoordinates] = useState({
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    fetchProperties();
  }, []);

  const fetchProperties = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        "https://backend.inyumba.co.ke/iNyumba/admin/properties/recordsall",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setProperties(response.data.response);
        setOriginalProperties(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching properties:", error);
        toast.error(error.response.data.response);
      });
  };

  const updateProperty = (propertyId) => {
    const token = localStorage.getItem("token");
    axios
      .patch(
        `https://backend.inyumba.co.ke/iNyumba/admin/properties/updateapprovalstatus/${propertyId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
        fetchProperties();
      })
      .catch((error) => {
        console.error("Error updating property status:", error);
        toast.error(error.response.data.response);
      });
  };

  const updateMonetaryStatusProperty = (propertyId) => {
    const token = localStorage.getItem("token");
    axios
      .patch(
        `https://backend.inyumba.co.ke/iNyumba/admin/properties/updatemonetarystatus/${propertyId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
        fetchProperties();
      })
      .catch((error) => {
        console.error("Error updating property status:", error);
        toast.error(error.response.data.response);
      });
  };

  const deleteProperty = (propertyId) => {
    const token = localStorage.getItem("token");
    axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/admin/properties/delete/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
        fetchProperties(); // Refetch properties after deletion
      })
      .catch((error) => {
        console.error("Error deleting property:", error);
        toast.error(error.response.data.response);
      });
  };

  const searchProperty = () => {
    if (!propertyId) return; // Don't search if no input
    const token = localStorage.getItem("token");
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/admin/properties/records/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setProperties([response.data.response]);
      })
      .catch((error) => {
        console.error("Error searching property:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearch = () => {
    setPropertyId(searchInput); // Update propertyId state before searching
    searchProperty(); // Then trigger search
  };

  const handleReset = () => {
    setPropertyId("");
    setSearchInput("");
    setProperties(originalProperties);
  };

  const handleDownload = (fileType) => {
    const endpoint = `https://backend.inyumba.co.ke/iNyumba/report/propertiesviewreports`;
    const token = localStorage.getItem("token");

    // Fetch and download file
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { format: fileType },
        responseType: "blob", // Important for file download
      })
      .then((response) => {
        const fileExtension = fileType === "pdf" ? "pdf" : "csv";
        const fileName = `inyumbapaidbankrecords.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Download is successfull");
      })
      .catch((error) => {
        console.error(`Error downloading ${fileType.toUpperCase()}:`, error);
        toast.error(error.response.data.response);
      });
  };

  const handleAddCoordinates = () => {
    const token = localStorage.getItem("token");

    axios
      .patch(
        `https://backend.inyumba.co.ke/iNyumba/admin/properties/updatecoordinates/${propertyId}`,
        { coordinates }, // Sending the coordinates as JSON
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
        fetchProperties(); // Refresh the properties list
        setIsModalOpen(false); // Close the modal
      })
      .catch((error) => {
        console.error("Error updating coordinates:", error);
        toast.error(error.response.data.response);
      });
  };

  const renderModal = () => {
    if (!isModalOpen) return null;

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-8 rounded shadow-lg">
          <h2 className="text-xl font-semibold mb-4">Add Coordinates</h2>
          <input
            type="text"
            placeholder="Latitude"
            value={coordinates.latitude}
            onChange={(e) =>
              setCoordinates({ ...coordinates, latitude: e.target.value })
            }
            className="border border-gray-300 rounded-md py-2 px-4 mb-4 w-full"
          />
          <input
            type="text"
            placeholder="Longitude"
            value={coordinates.longitude}
            onChange={(e) =>
              setCoordinates({ ...coordinates, longitude: e.target.value })
            }
            className="border border-gray-300 rounded-md py-2 px-4 mb-4 w-full"
          />
          <div className="flex justify-end">
            <button
              onClick={() => setIsModalOpen(false)}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
            >
              Cancel
            </button>
            <button
              onClick={handleAddCoordinates}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Post
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Properties</h1>
          <div className="mb-4 flex">
            <input
              type="text"
              placeholder="Enter Property ID"
              value={searchInput}
              onChange={handleSearchInputChange}
              className="border border-gray-300 rounded-md py-2 px-4 w-full"
            />
            <button
              onClick={handleSearch}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 ml-2 rounded"
            >
              Search
            </button>
            <button
              onClick={handleReset}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 ml-2 rounded"
            >
              Reset
            </button>
            <button
              onClick={() => handleDownload("csv")}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm"
            >
              <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
              Download
            </button>
            <button
              onClick={() => handleDownload("pdf")}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
            >
              <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
              Download
            </button>
          </div>
          <table className="w-full">
            <thead>
              <tr>
                <th className="px-4 py-2">Property ID</th>
                <th className="px-4 py-2">Name</th>
                <th className="px-4 py-2">Owner Id</th>
                <th className="px-4 py-2">Approval Status</th>
                <th className="px-4 py-2">Monetary Status</th>
                <th className="px-4 py-2">Coordinates</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {properties.map((property) => (
                <tr key={property.id}>
                  <td className="border px-4 py-2">{property.property_id}</td>
                  <td className="border px-4 py-2">{property.title}</td>
                  <td className="border px-4 py-2">{property.owner_id}</td>
                  <td className="border px-4 py-2">
                    {property.approvalstatus}
                  </td>
                  <td className="border px-4 py-2">
                    {property.monetarystatus}
                  </td>
                  <td className="border px-4 py-2">{property.coordinates}</td>
                  <td className="border px-8 py-4">
                    <button
                      onClick={() => updateProperty(property.property_id)}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded mr-2"
                    >
                      Update Approval
                    </button>
                    <button
                      onClick={() =>
                        updateMonetaryStatusProperty(property.property_id)
                      }
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mr-2"
                    >
                      Update Monetary Status
                    </button>
                    <button
                      onClick={() => {
                        setPropertyId(property.property_id);
                        setIsModalOpen(true);
                      }}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2"
                    >
                      Add Coordinates
                    </button>
                    <button
                      onClick={() => deleteProperty(property.property_id)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {renderModal()} {/* Render the modal */}
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default PropertyPage;
