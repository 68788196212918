import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";

const VerifyCodes = () => {
  const [codes, setCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Number of items per page

  useEffect(() => {
    fetchVerifyCodes();
  }, []);

  const fetchVerifyCodes = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        "https://backend.inyumba.co.ke/iNyumba/viewverifycodes",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCodes(response.data.response);
    } catch (error) {
      toast.error(error.response?.data?.response || "Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  // Calculate the items to display on the current page
  const offset = currentPage * itemsPerPage;
  const currentItems = codes.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(codes.length / itemsPerPage);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="p-6">
          <h1 className="text-2xl font-bold text-center mb-6">Verify Codes</h1>

          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="bg-gray-200">
                <th className="py-2 px-4 border-b">User ID</th>
                <th className="py-2 px-4 border-b">Code</th>
                <th className="py-2 px-4 border-b">Intent</th>
                <th className="py-2 px-4 border-b">Otp Resend Attempts</th>
                <th className="py-2 px-4 border-b">Email Verify Token</th>
                <th className="py-2 px-4 border-b">Email Expiry</th>
                <th className="py-2 px-4 border-b">OTP Expiry</th>
                <th className="py-2 px-4 border-b">Created At</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((code, index) => (
                <tr key={index} className="border-b hover:bg-gray-100">
                  <td className="py-2 px-4">{code.user_id}</td>
                  <td className="py-2 px-4">{code.code}</td>
                  <td className="py-2 px-4">{code.intent}</td>
                  <td className="py-2 px-4">{code.otp_resend_attempts}</td>
                  <td className="py-2 px-4">{code.email_verify_token}</td>
                  <td className="py-2 px-4">{code.email_exp}</td>
                  <td className="py-2 px-4">{code.otp_exp}</td>
                  <td className="py-2 px-4">
                    {new Date(code.created_at).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Component */}
          <div className="flex justify-center mt-4">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"flex space-x-2"}
              pageClassName={
                "px-3 py-1 border rounded cursor-pointer hover:bg-gray-200"
              }
              activeClassName={"bg-gray-300 font-bold"}
              previousClassName={"px-3 py-1 border rounded cursor-pointer"}
              nextClassName={"px-3 py-1 border rounded cursor-pointer"}
              disabledClassName={"opacity-50 cursor-not-allowed"}
            />
          </div>
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default VerifyCodes;
