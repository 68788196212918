import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";

const InputField = ({ label, value, onChange, type = "text", ...props }) => (
  <div className="mb-4">
    <label className="block text-gray-700 mb-2">{label}</label>
    <input
      type={type}
      value={value}
      onChange={onChange}
      className="w-full px-3 py-2 border rounded"
      {...props}
    />
  </div>
);

const SMSSender = () => {
  const [smsCategory, setSMSCategory] = useState("tenants");
  const [message, setMessage] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  const maxMessageLength = 144;

  const validateFields = () => {
    if (!message) {
      toast.error("Message is required");
      return false;
    }

    if (smsCategory === "tenantsByProperty" && !propertyId) {
      toast.error("Property ID is required");
      return false;
    }

    return true;
  };

  const handleEmailSend = async () => {
    if (!validateFields()) return;

    setLoading(true);

    let endpoint = "";
    let data = {
      message,
    };

    switch (smsCategory) {
      case "tenants":
        endpoint = "https://backend.inyumba.co.ke/iNyumba/admin/sms/tenants";
        break;
      case "landlords":
        endpoint = "https://backend.inyumba.co.ke/iNyumba/admin/sms/landlords";
        break;
      case "all":
        endpoint = "https://backend.inyumba.co.ke/iNyumba/admin/sms/all";
        break;
      case "tenantsByProperty":
        endpoint = `https://backend.inyumba.co.ke/iNyumba/admin/sms/tenants/${propertyId}`;
        break;
      default:
        break;
    }

    try {
      await axios.post(endpoint, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      toast.success("Email sent successfully!");
      // Clear all states after sending
      setMessage("");
      setPropertyId("");
    } catch (error) {
      const errorMessage =
        error.response?.data?.response || "An error occurred.";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-6">Send SMS</h1>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">SMS Category</label>
            <select
              value={smsCategory}
              onChange={(e) => setSMSCategory(e.target.value)}
              className="w-full px-3 py-2 border rounded"
            >
              <option value="tenants">All Tenants</option>
              <option value="landlords">All Landlords</option>
              <option value="all">Everyone</option>
              <option value="tenantsByProperty">Tenants by Property ID</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Message</label>
            <textarea
              value={message}
              onChange={(e) => {
                const text = e.target.value;
                if (text.length <= maxMessageLength) {
                  setMessage(text);
                }
              }}
              className="w-full h-40 px-5 py-2 border rounded"
            />
            <p className="text-sm text-gray-500 mt-1">
              {message.length}/{maxMessageLength} characters
            </p>
          </div>

          {smsCategory === "tenantsByProperty" && (
            <InputField
              label="Property ID"
              value={propertyId}
              onChange={(e) => setPropertyId(e.target.value)}
            />
          )}

          <button
            onClick={handleEmailSend}
            disabled={loading}
            className={`bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 ${
              loading ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            {loading ? "Sending..." : "Send Email"}
          </button>
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default SMSSender;
