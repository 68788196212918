import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";

const Video = () => {
  const [rentalId, setRentalId] = useState("");
  const [videos, setVideos] = useState([]);
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken(token);
  }, []);

  const handleInputChange = (e) => {
    setRentalId(e.target.value);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchVideos();
    }
  };

  const fetchVideos = () => {
    if (!rentalId) {
      toast.error("Please enter a rental ID.");
      return;
    }

    axios
      .get(`https://backend.inyumba.co.ke/iNyumba/videos/fetch/${rentalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setVideos(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching videos:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleDeleteVideo = (videoId) => {
    axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/videos/delete/${videoId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
        // Remove the deleted video from videos state
        setVideos(videos.filter((video) => video.video_id !== videoId));
      })
      .catch((error) => {
        toast.error(error.response.data.response);
        console.error("Error deleting video:", error);
      });
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Rental Videos</h1>

          {/* Input field for rental ID */}
          <div className="mb-4 flex items-center justify-center">
            <input
              type="text"
              value={rentalId}
              onChange={handleInputChange}
              onKeyPress={handleEnterKeyPress}
              placeholder="Enter Rental ID and press Enter..."
              className="border border-gray-300 rounded-md py-2 px-4 w-64 focus:outline-none focus:border-blue-500 mr-2"
            />
            {/* Button for fetching videos */}
            <button
              onClick={fetchVideos}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
            >
              Fetch Videos
            </button>
          </div>

          {/* Display videos */}
          <div className="mt-8">
            <h2 className="text-xl font-semibold mb-2">Videos</h2>
            <div className="flex flex-wrap">
              {videos.map((video, index) => (
                <div key={index} className="w-1/4 p-4">
                  <video controls className="w-full h-auto cursor-pointer">
                    <source src={video.video_url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  {/* Delete button */}
                  <button
                    onClick={() => handleDeleteVideo(video.video_id)}
                    className="block mt-2 text-red-500 font-semibold"
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default Video;
