import React, { useState, useEffect } from "react";
import axios from "axios";
import { EmailGuard } from "../App";
import { AuthGuard } from "../App";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner"; // Import loader

const PromoCodesPage = () => {
  const [promoCodes, setPromoCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const [selectedPromoCode, setSelectedPromoCode] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(""); // "add" or "update"
  const [modalData, setModalData] = useState({
    code: "",
    amount: "",
    expiry_date: "",
    usage_limit: "",
  });

  useEffect(() => {
    fetchPromoCodes();
  }, []);

  const fetchPromoCodes = () => {
    const token = localStorage.getItem("token");
    axios
      .get("https://backend.inyumba.co.ke/iNyumba/promo-codes/fetchall", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPromoCodes(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching promo codes:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleAddPromoCode = () => {
    setIsLoading(true); // Set loading state to true
    const token = localStorage.getItem("token");
    const { code, amount, expiry_date, usage_limit } = modalData;
    axios
      .post(
        "https://backend.inyumba.co.ke/iNyumba/promo-codes/add",
        {
          code,
          amount,
          expiry_date,
          usage_limit,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setIsModalOpen(false);
        setIsLoading(false); // Set loading state to false
        fetchPromoCodes();
        setModalData({
          code: "",
          amount: "",
          expiry_date: "",
          usage_limit: "",
        });
        toast.success(response.data.response);
      })
      .catch((error) => {
        setIsLoading(false); // Set loading state to false
        console.error("Error adding promo code:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleUpdatePromoCode = () => {
    setIsLoading(true); // Set loading state to true
    const token = localStorage.getItem("token");
    const { code, amount, expiry_date, usage_limit } = modalData;
    axios
      .patch(
        `https://backend.inyumba.co.ke/iNyumba/promo-codes/update/${selectedPromoCode}`,
        {
          code,
          amount,
          expiry_date,
          usage_limit,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Specify content type as JSON
          },
        }
      )
      .then((response) => {
        setIsModalOpen(false);
        fetchPromoCodes();
        setModalData({
          code: "",
          amount: "",
          expiry_date: "",
          usage_limit: "",
        });
        setIsLoading(false); // Set loading state to false
        toast.success(response.data.response);
      })
      .catch((error) => {
        setIsLoading(false); // Set loading state to false
        console.error("Error updating promo code:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleOpenAddModal = () => {
    setModalData({
      code: "",
      amount: "",
      expiry_date: "",
      usage_limit: "",
    });
    setSelectedPromoCode(null); // Clear the selected promo code
    setModalAction("add"); // Set action to "add"
    setIsModalOpen(true);
  };

  const handleOpenUpdateModal = (promoCode) => {
    setSelectedPromoCode(promoCode.promo_code_id);
    setModalData({
      code: promoCode.code,
      amount: promoCode.amount,
      expiry_date: promoCode.expiry_date,
      usage_limit: promoCode.usage_limit,
    });
    setModalAction("update"); // Set action to "update"
    setIsModalOpen(true);
  };

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setModalData({ ...modalData, [name]: value });
  };

  const handleDeletePromoCode = (promoCodeId) => {
    setIsLoading(true); // Set loading state to true
    const token = localStorage.getItem("token");
    axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/promo-codes/delete/${promoCodeId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        fetchPromoCodes();
        setIsLoading(false); // Set loading state to false
        toast.success(response.data.response);
      })
      .catch((error) => {
        setIsLoading(false); // Set loading state to false
        console.error("Error deleting promo code:", error);
        toast.error(error.response.data.response);
      });
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Promo Codes</h1>
          <button
            onClick={handleOpenAddModal}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Add Promo Code
          </button>
          <table className="mt-4 w-full">
            <thead>
              <tr>
                <th className="px-4 py-2">Code</th>
                <th className="px-4 py-2">Amount</th>
                <th className="px-4 py-2">Expiry Date</th>
                <th className="px-4 py-2">Usage Limit</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {promoCodes.map((promoCode) => (
                <tr key={promoCode.promo_code_id}>
                  <td className="border px-4 py-2">{promoCode.code}</td>
                  <td className="border px-4 py-2">{promoCode.amount}</td>
                  <td className="border px-4 py-2">
                    {new Date(promoCode.expiry_date).toLocaleString()}
                  </td>
                  <td className="border px-4 py-2">{promoCode.usage_limit}</td>
                  <td className="border px-4 py-2">
                    <button
                      onClick={() => handleOpenUpdateModal(promoCode)}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                    >
                      Update
                    </button>
                    <button
                      onClick={() =>
                        handleDeletePromoCode(promoCode.promo_code_id)
                      }
                      disabled={isLoading}
                      className={`${
                        isLoading
                          ? "bg-gray-500"
                          : "bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      } text-white py-2 px-4 rounded-md focus:outline-none`}
                    >
                      {isLoading ? (
                        <Oval
                          height={20}
                          width={20}
                          color="white"
                          visible={true}
                          ariaLabel="loading-indicator"
                          secondaryColor="white"
                        />
                      ) : (
                        "Delete"
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {isModalOpen && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
            <div className="bg-white p-6 rounded-lg w-96">
              {modalAction === "update" ? (
                <>
                  <h2 className="text-lg font-semibold mb-4">
                    Update Promo Code
                  </h2>
                  <input
                    type="text"
                    name="code"
                    value={modalData.code}
                    onChange={handleModalInputChange}
                    placeholder="Code"
                    className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                  />
                  <input
                    type="number"
                    name="amount"
                    value={modalData.amount}
                    onChange={handleModalInputChange}
                    placeholder="Amount"
                    className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                  />
                  <input
                    type="date"
                    name="expiry_date"
                    value={modalData.expiry_date}
                    onChange={handleModalInputChange}
                    placeholder="Expiry Date"
                    className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                  />
                  <input
                    type="number"
                    name="usage_limit"
                    value={modalData.usage_limit}
                    onChange={handleModalInputChange}
                    placeholder="Usage Limit"
                    className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                  />
                  <div className="flex justify-end">
                    <button
                      onClick={handleUpdatePromoCode}
                      disabled={isLoading}
                      className={`${
                        isLoading
                          ? "bg-gray-500"
                          : "bg-green-500 hover:bg-green-700"
                      } text-white font-bold py-2 px-4 rounded mr-2`}
                    >
                      {isLoading ? (
                        <Oval
                          height={20}
                          width={20}
                          color="white"
                          ariaLabel="loading"
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                    <button
                      onClick={() => setIsModalOpen(false)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <h2 className="text-lg font-semibold mb-4">Add Promo Code</h2>
                  <input
                    type="text"
                    name="code"
                    value={modalData.code}
                    onChange={handleModalInputChange}
                    placeholder="Code"
                    className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                  />
                  <input
                    type="number"
                    name="amount"
                    value={modalData.amount}
                    onChange={handleModalInputChange}
                    placeholder="Amount"
                    className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                  />
                  <input
                    type="date"
                    name="expiry_date"
                    value={modalData.expiry_date}
                    onChange={handleModalInputChange}
                    placeholder="Expiry Date"
                    className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                  />
                  <input
                    type="number"
                    name="usage_limit"
                    value={modalData.usage_limit}
                    onChange={handleModalInputChange}
                    placeholder="Usage Limit"
                    className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                  />
                  <div className="flex justify-end">
                    <button
                      onClick={handleAddPromoCode}
                      disabled={isLoading}
                      className={`${
                        isLoading
                          ? "bg-gray-500"
                          : "bg-blue-500 hover:bg-blue-700"
                      } text-white font-bold py-2 px-4 rounded mr-2`}
                    >
                      {isLoading ? (
                        <Oval
                          height={20}
                          width={20}
                          color="white"
                          ariaLabel="loading"
                        />
                      ) : (
                        "Add"
                      )}
                    </button>
                    <button
                      onClick={() => setIsModalOpen(false)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </AuthGuard>
    </EmailGuard>
  );
};

export default PromoCodesPage;
