import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";

const SpecificRental = () => {
  const [rental, setRental] = useState({});
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [showAddPhotoModal, setShowAddPhotoModal] = useState(false);
  const [showAddVideoModal, setShowAddVideoModal] = useState(false);
  const [photoFile, setPhotoFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const rentalId = localStorage.getItem("selectedRentalId");
    const token = localStorage.getItem("token");

    if (rentalId && token) {
      setToken(token);

      // Fetch rental data
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/admin/rentals/specific/${rentalId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setRental(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching rental:", error);
        });

      // Fetch photos
      axios
        .get(`https://backend.inyumba.co.ke/iNyumba/photos/fetch/${rentalId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setPhotos(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching photos:", error);
        });

      // Fetch videos
      axios
        .get(`https://backend.inyumba.co.ke/iNyumba/videos/fetch/${rentalId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setVideos(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching videos:", error);
        });
    }
  }, []);

  const handleAddPhoto = () => {
    setShowAddPhotoModal(true);
  };

  const handleAddVideo = () => {
    setShowAddVideoModal(true);
  };

  const closeModal = () => {
    setShowAddPhotoModal(false);
    setShowAddVideoModal(false);
  };

  const handlePhotoFileChange = (e) => {
    setPhotoFile(e.target.files[0]);
  };

  const handleVideoFileChange = (e) => {
    setVideoFile(e.target.files[0]);
  };

  const addPhoto = () => {
    const formData = new FormData();
    formData.append("image", photoFile);

    axios
      .post(
        "https://backend.inyumba.co.ke/iNyumba/images/images/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Photo uploaded successfully!");
        // Update photos state with the response URL
        const newPhoto = { photo_url: response.data.response.coverUri };
        setPhotos([...photos, newPhoto]);
        // After receiving the response, add the photo to the rental
        addPhotoToRental(newPhoto);
        closeModal();
      })
      .catch((error) => {
        toast.error(error.response.data.response);
        console.error("Error uploading photo:", error);
      });
  };

  const addPhotoToRental = (photo) => {
    const rentalId = localStorage.getItem("selectedRentalId");
    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/photos/add/${rentalId}`,
        {
          photo_url: photo.photo_url,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
      })
      .catch((error) => {
        toast.error(error.response.data.response);
        console.error("Error adding photo to rental:", error);
      });
  };

  const handleDeletePhoto = (photoId) => {
    localStorage.setItem("deletePhotoId", photoId);
    navigate("/landlord/deletephoto");
  };

  const handleDeleteVideo = (videoId) => {
    localStorage.setItem("deleteVideoId", videoId);
    navigate("/landlord/deletevideo");
  };

  const addVideo = () => {
    const formData = new FormData();
    formData.append("video", videoFile);

    axios
      .post(
        "https://backend.inyumba.co.ke/iNyumba/images/images/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Video uploaded successfully!");
        // Update videos state with the response URL
        const newVideo = { video_url: response.data.response.coverUri };

        setVideos([...videos, newVideo]);
        addVideoToRental(newVideo);
        closeModal();
      })
      .catch((error) => {
        toast.error("Error uploading video!");
        console.error("Error uploading video:", error);
      });
  };

  const addVideoToRental = (video) => {
    const rentalId = localStorage.getItem("selectedRentalId");
    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/videos/add/${rentalId}`,
        {
          video_url: video.video_url,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
      })
      .catch((error) => {
        toast.error(error.response.data.response);
        console.error("Error adding video to rental:", error);
      });
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Specific Rental</h1>

          {/* Display rental details */}
          <div>
            <p>
              <strong>Description:</strong> {rental.description}
            </p>
            <p>
              <strong>House Number:</strong> {rental.house_number}
            </p>
          </div>

          {/* Button for adding photos */}
          <button
            onClick={handleAddPhoto}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Add Photo
          </button>

          {/* Button for adding videos */}
          <button
            onClick={handleAddVideo}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4"
          >
            Add Video
          </button>
          {/* Modal for adding photos */}
          <Modal
            isOpen={showAddPhotoModal}
            onRequestClose={closeModal}
            className="modal"
          >
            <div className="bg-white w-1/3 p-6 rounded-lg">
              <h2 className="text-2xl font-semibold mb-4">Add Photo</h2>
              <div className="mb-4">
                <label
                  htmlFor="photoFile"
                  className="block text-sm font-semibold"
                >
                  Photo File:
                </label>
                <input
                  type="file"
                  id="videoFile"
                  className="w-full border rounded-md py-2 px-3"
                  onChange={handlePhotoFileChange}
                />
              </div>
              <div className="flex justify-end">
                <button
                  onClick={addPhoto}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                >
                  Add
                </button>
                <button
                  onClick={closeModal}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>

          {/* Modal for adding videos */}
          <Modal
            isOpen={showAddVideoModal}
            onRequestClose={closeModal}
            className="modal"
          >
            <div className="bg-white w-1/3 p-6 rounded-lg">
              <h2 className="text-2xl font-semibold mb-4">Add Video</h2>
              <div className="mb-4">
                <label
                  htmlFor="videoFile"
                  className="block text-sm font-semibold"
                >
                  Video File:
                </label>
                <input
                  type="file"
                  id="videoFile"
                  className="w-full border rounded-md py-2 px-3"
                  onChange={handleVideoFileChange}
                />
              </div>
              <div className="flex justify-end">
                <button
                  onClick={addVideo}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                >
                  Add
                </button>
                <button
                  onClick={closeModal}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>

          {/* Display photos */}
          <div className="mt-8">
            <h2 className="text-xl font-semibold mb-2">Photos</h2>
            <div className="flex flex-wrap">
              {photos.map((photo, index) => (
                <div key={index} className="w-1/4 p-4">
                  <img
                    src={photo.photo_url}
                    alt={`rental photo ${index}`}
                    className="w-full h-auto object-cover cursor-pointer"
                  />
                  {/* Delete button */}
                  <button
                    onClick={() => handleDeletePhoto(photo.photo_id)}
                    className="block mt-2 text-red-500 font-semibold"
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* Display videos */}
          <div className="mt-8">
            <h2 className="text-xl font-semibold mb-2">Videos</h2>
            <div className="flex flex-wrap">
              {videos.map((video, index) => (
                <div key={index} className="w-1/4 p-4">
                  <iframe
                    title={`rental video ${index}`}
                    src={video.video_url}
                    className="w-full h-auto"
                    allowFullScreen
                  ></iframe>
                  {/* Delete button */}
                  <button
                    onClick={() => handleDeleteVideo(video.video_id)}
                    className="block mt-2 text-red-500 font-semibold"
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default SpecificRental;
