import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";

const UpdateProperty = () => {
  const { selectedPropertyId } = useParams();
  const navigate = useNavigate();
  const [property, setProperty] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [county, setCounty] = useState("");
  const PropertyId = localStorage.getItem("selectedPropertyId");
  const token = localStorage.getItem("token");

  useEffect(() => {
    // Fetch property details
    if (token) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/admin/properties/records/${PropertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const { title, description, location, county } =
            response.data.response;
          setProperty(response.data.response);
          setTitle(title);
          setDescription(description);
          setLocation(location);
          setCounty(county);
        })
        .catch((error) => {
          console.error("Error fetching property:", error);
        });
    }
  }, [selectedPropertyId]);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const token = localStorage.getItem("token");
    if (token) {
      axios
        .patch(
          `https://backend.inyumba.co.ke/iNyumba/admin/properties/update/${PropertyId}`,
          {
            title,
            description,
            location,
            county,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // Handle successful update
          toast.success(response.data.response);
          navigate(-1); // Go back one step in navigation
        })
        .catch((error) => {
          toast.error(error.response.data.response);
          console.error("Error updating property:", error);
        });
    }
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto mt-8">
          <h1 className="text-3xl font-semibold mb-4">Update Property</h1>
          {property && (
            <form onSubmit={handleSubmit} className="max-w-md mx-auto">
              <div className="mb-4">
                <label
                  htmlFor="title"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="description"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="location"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Location
                </label>
                <input
                  type="text"
                  id="location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="county"
                  className="block text-gray-700 font-bold mb-2"
                >
                  County
                </label>
                <input
                  type="text"
                  id="county"
                  value={county}
                  onChange={(e) => setCounty(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Update
              </button>
            </form>
          )}
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default UpdateProperty;
