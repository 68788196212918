import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";

const InputField = ({ label, value, onChange, type = "text", ...props }) => (
  <div className="mb-4">
    <label className="block text-gray-700 mb-2">{label}</label>
    <input
      type={type}
      value={value}
      onChange={onChange}
      className="w-full px-3 py-2 border rounded"
      {...props}
    />
  </div>
);

const EmailSender = () => {
  const [emailCategory, setEmailCategory] = useState("tenants");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const token = localStorage.getItem("token");

  const validateFields = () => {
    if (!subject || !message) {
      toast.error("Subject and message are required");
      return false;
    }

    if (emailCategory === "tenantsByProperty" && !propertyId) {
      toast.error("Property ID is required");
      return false;
    }

    if (emailCategory === "specific" && !email) {
      toast.error("Email address is required");
      return false;
    }

    return true;
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    const uploadedImageUrls = [];

    for (const file of files) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const response = await axios.post(
          "https://backend.inyumba.co.ke/iNyumba/images/images/upload",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        uploadedImageUrls.push(response.data.response.coverUri); // Collect uploaded image URLs
      } catch (error) {
        console.error("Error uploading photo:", error);
        toast.error("Failed to upload image.");
      }
    }

    setImageFiles(uploadedImageUrls); // Store image URLs
    const imageTags = uploadedImageUrls
      .map((url) => `<img src="${url}" alt="Image" style="max-width: 100%;" />`)
      .join(""); // Create image tags for the message
    setMessage((prevMessage) => prevMessage + imageTags); // Append image tags to message
  };

  const clearImages = () => {
    setImageFiles([]); // Clear image files
    toast.info("Attached images cleared.");
  };

  const handleEmailSend = async () => {
    if (!validateFields()) return;

    setLoading(true);

    let endpoint = "";
    let data = {
      subject,
      message:
        message +
        imageFiles
          .map(
            (url) => `<img src="${url}" alt="Image" style="max-width: 100%;" />`
          )
          .join(""),
    }; // Append image tags

    switch (emailCategory) {
      case "tenants":
        endpoint = "https://backend.inyumba.co.ke/iNyumba/admin/email/tenants";
        break;
      case "landlords":
        endpoint =
          "https://backend.inyumba.co.ke/iNyumba/admin/email/landlords";
        break;
      case "all":
        endpoint = "https://backend.inyumba.co.ke/iNyumba/admin/email/all";
        break;
      case "tenantsByProperty":
        endpoint = `https://backend.inyumba.co.ke/iNyumba/admin/email/tenants/${propertyId}`;
        break;
      case "specific":
        data = { ...data, email };
        endpoint = "https://backend.inyumba.co.ke/iNyumba/admin/email/specific";
        break;
      default:
        break;
    }

    try {
      await axios.post(endpoint, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      toast.success("Email sent successfully!");
      // Clear all states after sending
      setSubject("");
      setMessage("");
      setPropertyId("");
      setEmail("");
      setImageFiles([]);
    } catch (error) {
      const errorMessage =
        error.response?.data?.response || "An error occurred.";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-6">Send Emails</h1>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Email Category</label>
            <select
              value={emailCategory}
              onChange={(e) => setEmailCategory(e.target.value)}
              className="w-full px-3 py-2 border rounded"
            >
              <option value="tenants">All Tenants</option>
              <option value="landlords">All Landlords</option>
              <option value="all">Everyone</option>
              <option value="tenantsByProperty">Tenants by Property ID</option>
              <option value="specific">Specific User by Email</option>
            </select>
          </div>

          <InputField
            label="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Message</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full h-40 px-5 py-2 border rounded"
            />
          </div>

          {emailCategory === "tenantsByProperty" && (
            <InputField
              label="Property ID"
              value={propertyId}
              onChange={(e) => setPropertyId(e.target.value)}
            />
          )}

          {emailCategory === "specific" && (
            <InputField
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          )}

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Attach Images</label>
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageUpload}
              className="w-full px-3 py-2 border rounded"
            />
            {imageFiles.length > 0 && (
              <div className="mt-2">
                <p>Attached Images: {imageFiles.length}</p>
                <button
                  onClick={clearImages}
                  className="bg-red-500 text-white py-1 px-4 rounded mt-2 hover:bg-red-600"
                >
                  Clear Images
                </button>
              </div>
            )}
          </div>

          <button
            onClick={handleEmailSend}
            disabled={loading}
            className={`bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 ${
              loading ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            {loading ? "Sending..." : "Send Email"}
          </button>
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default EmailSender;
