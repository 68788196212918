import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons"; // Import icons
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";
import { Oval } from "react-loader-spinner"; // Import loader

const WithdrawalsLandlordAdminPage = () => {
  const [balance, setBalance] = useState(null);
  const [showBalance, setShowBalance] = useState(true);
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedMode, setSelectedMode] = useState(null);
  const [showMobileMoneyOptions, setShowMobileMoneyOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const [propertyId, setPropertyId] = useState(""); // New state for propertyId

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchBalance = async () => {
      if (token && propertyId) {
        try {
          const response = await axios.get(
            `https://backend.inyumba.co.ke/iNyumba/balance/owner/${propertyId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.data && response.data.response.length > 0) {
            setBalance(response.data.response[0].balance);
          }
        } catch (error) {
          console.error("Error fetching balance:", error);
          toast.error(error.response.data.response);
        }
      }
    };

    fetchBalance();
  }, [token, propertyId]);

  const handleWithdrawalSubmit = async (e) => {
    e.preventDefault();

    if (!token || !withdrawalAmount) {
      console.error("Withdrawal amount and channel are required.");
      return;
    }

    setIsLoading(true); // Set loading state to true

    try {
      let response;

      if (selectedMode === "mobilemoney") {
        const requestData = {
          amount: withdrawalAmount,
          phone_number: phoneNumber,
          channel: selectedChannel,
          propertyId: propertyId, // Include propertyId in the request
        };

        const jsonData = JSON.stringify(requestData);
        response = await axios.post(
          `https://backend.inyumba.co.ke/iNyumba/landlordsettlementlinkinitiated`,
          jsonData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.responseCode === 200) {
          if (
            response.data.response ===
            "Withdrawal has been blocked kindly contact admin"
          ) {
            toast.info("Withdrawal has been blocked. Kindly contact admin.");
          } else if (
            response.data.response ===
            "Kindly request for the merchant to check their email for the withdrawal link."
          ) {
            toast.info(
              "Kindly request for the merchant to check their email for the withdrawal link."
            );
          } else if (
            response.data.response ===
            "You have insufficient funds in your account. Transaction cost is 150"
          ) {
            toast.error(
              "You have insufficient funds in your account. Transaction cost is 150"
            );
          } else if (response.data.responseCode === 201) {
            toast.error(response.data.response);
          }
        } else {
          toast.error(response.data.response);
        }
      }
    } catch (error) {
      toast.error(error.response.data.response);
      console.error("Error submitting withdrawal:", error);
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  const toggleShowBalance = () => {
    setShowBalance(!showBalance);
  };

  const handleModeSelection = (mode) => {
    setSelectedMode(mode);

    if (mode === "mobilemoney") {
      setShowMobileMoneyOptions(true);
    }
  };

  const handleMobileMoneyClick = (channel) => {
    setSelectedChannel(channel);
  };

  return (
    <EmailGuard>
          <AuthGuard>
      <div className="p-6">
        <h1 className="text-2xl font-bold mb-4">Withdraw Funds</h1>

        <div className="border border-gray-300 rounded-lg p-6 bg-white">
          <div className="mb-6">
            <div className="flex items-center mb-2">
              <span className="mr-2">Available Balance:</span>
              <span className="font-bold text-lg">
                {showBalance ? balance : "******"}
              </span>
              <button
                onClick={toggleShowBalance}
                className="ml-4 focus:outline-none"
              >
                <FontAwesomeIcon
                  icon={showBalance ? faEyeSlash : faEye}
                  className="text-gray-500"
                />
              </button>
            </div>
          </div>

          <div className="mb-4">
            <label className="block mb-2 font-semibold">Property ID</label>
            <input
              type="text"
              value={propertyId}
              onChange={(e) => setPropertyId(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2 mb-4"
              placeholder="Enter Property ID"
            />
          </div>

          <div className="mb-4">
            <label className="block mb-2 font-semibold">Select Mode</label>
            <div className="flex space-x-4">
              <button
                className={`flex items-center focus:outline-none ${
                  selectedMode === "mobilemoney"
                    ? "text-blue-500"
                    : "text-gray-500"
                }`}
                onClick={() => handleModeSelection("mobilemoney")}
              >
                <FontAwesomeIcon icon={faMobileAlt} className="mr-2" />
                <span className="font-semibold">Mobile Money</span>
              </button>
            </div>
          </div>

          {showMobileMoneyOptions && (
            <div className="mb-4">
              <label className="block mb-2 font-semibold">Channel</label>
              <div className="flex space-x-4">
                <button
                  className={`flex items-center focus:outline-none ${
                    selectedChannel === "mpesa"
                      ? "text-blue-500"
                      : "text-gray-500"
                  }`}
                  onClick={() => handleMobileMoneyClick("mpesa")}
                >
                  <span className="font-semibold">M-Pesa</span>
                </button>
                <button
                  className={`flex items-center focus:outline-none ${
                    selectedChannel === "airtel"
                      ? "text-blue-500"
                      : "text-gray-500"
                  }`}
                  onClick={() => handleMobileMoneyClick("airtelmoney")}
                >
                  <span className="font-semibold">Airtel Money</span>
                </button>
              </div>
              <label className="block mb-2 font-semibold">Amount</label>
              <input
                type="text"
                value={withdrawalAmount}
                onChange={(e) => setWithdrawalAmount(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2 mb-4"
              />
              <label className="block mb-2 font-semibold">Phone Number</label>
              <input
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2 mb-4"
              />
            </div>
          )}

          <button
            onClick={handleWithdrawalSubmit}
            disabled={isLoading}
            className={`${
              isLoading ? "bg-gray-500" : "bg-blue-500"
            } text-white py-2 px-4 rounded-md focus:outline-none`}
          >
            {isLoading ? (
              <Oval
                height={20}
                width={20}
                color="white"
                visible={true}
                ariaLabel="loading-indicator"
                secondaryColor="white"
              />
            ) : (
              "Submit Withdrawal"
            )}
          </button>
        </div>
      </div>
    </AuthGuard>
        </EmailGuard>
  );
};

export default WithdrawalsLandlordAdminPage;
