import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";
import { toast } from "react-toastify";

const OfflinePayments = () => {
  const [offlinepayments, setOfflinePayments] = useState([]);

  useEffect(() => {
    fetchOfflinePayments();
  }, []);

  const fetchOfflinePayments = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        "https://backend.inyumba.co.ke/iNyumba/user/offlinepayments/viewpayments",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setOfflinePayments(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching User Settlements:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleUpdate = (offlineid) => {
    const token = localStorage.getItem("token");
    if (
      window.confirm("Are you sure you want to update this offline payment?")
    ) {
      axios
        .patch(
          `https://backend.inyumba.co.ke/iNyumba/user/offlinepayments/update/${offlineid}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.response);
          fetchOfflinePayments();
        })
        .catch((error) => {
          console.error("Error updating offline payment:", error);
          toast.error("Error updating offline payment");
        });
    }
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">OfflinePayments</h1>
          <table className="w-full">
            <thead>
              <tr>
                <th className="px-4 py-2">transactionid</th>
                <th className="px-4 py-2">Total Amount Paid</th>
                <th className="px-4 py-2">transactiontype</th>
                <th className="px-4 py-2">transactiontime</th>
                <th className="px-4 py-2">msisdn</th>
                <th className="px-4 py-2">firstname</th>
                <th className="px-4 py-2">middlename</th>
                <th className="px-4 py-2">lastname</th>
                <th className="px-4 py-2">billreference</th>
                <th className="px-4 py-2">organizationbalance</th>
                <th className="px-4 py-2">status</th>
                <th className="px-4 py-2">Created On</th>
              </tr>
            </thead>
            <tbody>
              {offlinepayments.map((offlinepayments) => (
                <tr key={offlinepayments.transactionid}>
                  <td className="border px-4 py-2">
                    {offlinepayments.transactionid}
                  </td>
                  <td className="border px-4 py-2">{offlinepayments.amount}</td>
                  <td className="border px-4 py-2">
                    {offlinepayments.transactiontype}
                  </td>
                  <td className="border px-4 py-2">
                    {offlinepayments.transactiontime}
                  </td>
                  <td className="border px-4 py-2">{offlinepayments.msisdn}</td>
                  <td className="border px-4 py-2">
                    {offlinepayments.firstname}
                  </td>
                  <td className="border px-4 py-2">
                    {offlinepayments.middlename}
                  </td>
                  <td className="border px-4 py-2">
                    {offlinepayments.lastname}
                  </td>
                  <td className="border px-4 py-2">
                    {offlinepayments.billreference}
                  </td>
                  <td className="offlinepayments px-4 py-2">
                    {offlinepayments.organizationbalance}
                  </td>
                  <td className="border px-4 py-2">{offlinepayments.status}</td>
                  <td className="border px-4 py-2">
                    {new Date(offlinepayments.created_at).toLocaleString()}
                  </td>
                  {/* Add the delete button here */}
                  <td className="border px-4 py-2">
                    <button
                      onClick={() =>
                        handleUpdate(offlinepayments.transactionid)
                      }
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
                    >
                      Update
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default OfflinePayments;
