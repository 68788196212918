import React, { useState, useEffect } from "react";
import axios from "axios";
import { EmailGuard } from "../App";
import { AuthGuard } from "../App";
import { toast } from "react-toastify";

const NotificationsPage = () => {
  const [notifications, setNotifications] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    notification: "",
  });

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        "https://backend.inyumba.co.ke/iNyumba/inappnotifications/viewallnotifications",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setNotifications(response.data.response); // Assign response directly
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleDeleteNotifications = (notificationId) => {
    const token = localStorage.getItem("token");
    axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/inappnotifications/erasenotification/${notificationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        fetchNotifications();
        toast.success(response.data.response);
      })
      .catch((error) => {
        console.error("Error deleting notification:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleAddNotifications = () => {
    const token = localStorage.getItem("token");
    const { notification } = modalData;
    axios
      .post(
        "https://backend.inyumba.co.ke/iNyumba/inappnotifications/sendtoall",
        {
          notification,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setIsModalOpen(false);
        fetchNotifications();
        setModalData({ notification: "" });
        toast.success(response.data.response);
      })
      .catch((error) => {
        console.error("Error adding notification:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setModalData({ ...modalData, [name]: value });
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Notifications</h1>
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Add Notifications
          </button>
          <table className="mt-4 w-full">
            <thead>
              <tr>
                <th className="px-4 py-2">Notifications</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {notifications.map((notification) => (
                <tr key={notification.notificationid}>
                  <td className="border px-4 py-2">
                    {notification.notification}
                  </td>
                  <td className="border px-4 py-2">
                    <button
                      onClick={() =>
                        handleDeleteNotifications(notification.notificationid)
                      }
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {isModalOpen && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
            <div className="bg-white p-6 rounded-lg w-96">
              <h2 className="text-lg font-semibold mb-4">Add Notifications</h2>
              <input
                type="text"
                name="notification"
                value={modalData.notification}
                onChange={handleModalInputChange}
                placeholder="Notification"
                className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
              />

              <div className="flex justify-end">
                <button
                  onClick={handleAddNotifications}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                >
                  Add
                </button>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </AuthGuard>
    </EmailGuard>
  );
};

export default NotificationsPage;
