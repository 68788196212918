import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker"; // Install react-datepicker
import "react-datepicker/dist/react-datepicker.css"; // Import styles for date picker
import { EmailGuard } from "../App";

const ManualReservations = () => {
  const [reservations, setReservations] = useState([]);
  const [users, setUsers] = useState([]);
  const [properties, setProperties] = useState([]);
  const [isAdding, setIsAdding] = useState(false); // Modal state for adding a reservation
  const [isEditing, setIsEditing] = useState(false); // Modal state for editing a reservation

  const [isLoading, setIsLoading] = useState(false);
  const [editingReservation, setEditingReservation] = useState(null);
  const [newReservation, setNewReservation] = useState({
    userid: "",
    propertyid: "",
    roomid: "",
    username: "",
    email: "",
    phone: "",
    entrydate: new Date(),
    housenumber: "",
    bookingstatus: "",
    depositpaid: "",
    paymentstatus: "",
  });

  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchReservations();
    fetchUsers();
    fetchProperties();
  }, []);

  const fetchReservations = async () => {
    try {
      const response = await axios.get(
        "https://backend.inyumba.co.ke/iNyumba/admin/manualreservations/reservation",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setReservations(response.data.response);
    } catch (error) {
      toast.error("Error fetching reservations");
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        "https://backend.inyumba.co.ke/iNyumba/admin/manualreservations/users",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers(response.data.response);
    } catch (error) {
      toast.error("Error fetching users");
    }
  };

  const fetchProperties = async () => {
    try {
      const response = await axios.get(
        "https://backend.inyumba.co.ke/iNyumba/admin/manualreservations/property",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const propertiesData = response.data.response.reduce((acc, property) => {
        const existingProperty = acc.find(
          (p) => p.property_id === property.property_id
        );
        if (existingProperty) {
          existingProperty.rentals.push({
            rental_id: property.rental_id,
            house_number: property.house_number,
          });
        } else {
          acc.push({
            property_id: property.property_id,
            title: property.title,
            rentals: [
              {
                rental_id: property.rental_id,
                house_number: property.house_number,
              },
            ],
          });
        }
        return acc;
      }, []);
      setProperties(propertiesData);
    } catch (error) {
      toast.error("Error fetching properties");
    }
  };

  const handleEditClick = (reservation) => {
    setEditingReservation(reservation);
    setNewReservation({
      userid: reservation.user_id,
      propertyid: reservation.property_id,
      roomid: reservation.rental_id,
      username: reservation.name,
      email: reservation.email,
      phone: reservation.phone,
      entrydate: new Date(reservation.entrydate),
      housenumber: reservation.house_number,
      bookingstatus: reservation.booking_status,
      depositpaid: reservation.deposit_paid,
      paymentstatus: reservation.payment_status,
    });
    setIsEditing(true); // Open edit modal
    setIsAdding(false); // Close add modal
  };

  const handleAddClick = () => {
    setIsAdding(true); // Open add modal
    setIsEditing(false); // Close edit modal
  };

  const handleUpdateReservation = async () => {
    try {
      setIsLoading(true);

      // Prepare the update payload with only the necessary fields
      const updatedFields = {
        phone: newReservation.phone,
        bookingstatus: newReservation.bookingstatus,
        paymentstatus: newReservation.paymentstatus,
        depositpaid: newReservation.depositpaid,
      };

      // Send the updated fields to the backend
      await axios.patch(
        `https://backend.inyumba.co.ke/iNyumba/admin/manualreservations/update/${editingReservation.reservations_id}`,
        updatedFields,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setIsLoading(false);
      setIsEditing(false); // Close modal
      fetchReservations(); // Refresh the list of reservations
      toast.success("Reservation updated successfully");
    } catch (error) {
      setIsLoading(false);
      toast.error("Error updating reservation");
    }
  };

  const handleAddReservation = async () => {
    try {
      setIsLoading(true);
      await axios.post(
        "https://backend.inyumba.co.ke/iNyumba/admin/manualreservations/post",
        {
          ...newReservation,
          entrydate: newReservation.entrydate.toISOString().split("T")[0],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setNewReservation({
        userid: "",
        propertyid: "",
        roomid: "",
        username: "",
        email: "",
        phone: "",
        entrydate: new Date(),
        housenumber: "",
        bookingstatus: "",
        depositpaid: "",
        paymentstatus: "",
      });
      setIsLoading(false);
      setIsAdding(false); // Close modal
      fetchReservations();
      toast.success("Reservation added successfully");
    } catch (error) {
      setIsLoading(false);
      toast.error("Error adding reservation");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReservation((prev) => ({ ...prev, [name]: value }));
  };

  const handleUserSelect = (e) => {
    const userId = e.target.value;
    const selectedUser = users.find((user) => user.uuid === userId);
    setNewReservation((prev) => ({
      ...prev,
      userid: userId,
      username: selectedUser?.name || "",
      email: selectedUser?.email || "",
      phone: selectedUser?.phone || "",
    }));
  };

  const handlePropertySelect = (e) => {
    const propertyId = e.target.value;
    const selectedProperty = properties.find(
      (property) => property.property_id === propertyId
    );

    // Log selected property for debugging
    console.log("Selected Property:", selectedProperty);

    if (selectedProperty) {
      // Reset house number and rentalid upon selecting a new property
      setNewReservation((prev) => ({
        ...prev,
        propertyid: propertyId,
        housenumber: "", // Reset house number
        rentalid: "", // Reset rental id
      }));
    }
  };

  const handleRentalSelect = (e) => {
    const rentalId = e.target.value;
    const selectedRental = properties
      .find((property) => property.property_id === newReservation.propertyid)
      ?.rentals.find((rental) => rental.rental_id === rentalId);

    // Log selected rental for debugging
    console.log("Selected Rental:", selectedRental);

    if (selectedRental) {
      setNewReservation((prev) => ({
        ...prev,
        roomid: rentalId,
        housenumber: selectedRental.house_number,
      }));
    }
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Manual Reservations</h1>

          {/* Add Reservation Button */}
          <button
            onClick={handleAddClick}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
          >
            Add Reservation
          </button>

          {/* Add Reservation Modal */}
          {isAdding && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg w-1/2">
                <h2 className="text-xl font-semibold mb-4">Add Reservation</h2>
                <form>
                  {/* User Selection */}
                  <select
                    name="userid"
                    value={newReservation.userid}
                    onChange={handleUserSelect}
                    className="mb-2 border rounded-md py-2 px-4 w-full"
                  >
                    <option value="">Select User</option>
                    {users.map((user) => (
                      <option key={user.uuid} value={user.uuid}>
                        {user.name}
                      </option>
                    ))}
                  </select>

                  <input
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={newReservation.username}
                    readOnly
                    className="mb-2 border rounded-md py-2 px-4 w-full"
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={newReservation.email}
                    readOnly
                    className="mb-2 border rounded-md py-2 px-4 w-full"
                  />
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    value={newReservation.phone}
                    readOnly
                    className="mb-2 border rounded-md py-2 px-4 w-full"
                  />

                  {/* Property Selection */}
                  <select
                    name="propertyid"
                    value={newReservation.propertyid}
                    onChange={handlePropertySelect}
                    className="mb-2 border rounded-md py-2 px-4 w-full"
                  >
                    <option value="">Select Property</option>
                    {properties.map((property) => (
                      <option
                        key={property.property_id} // Use property_id for key
                        value={property.property_id} // The value is property_id
                      >
                        {property.title}
                      </option>
                    ))}
                  </select>

                  {/* Optional: Display rentals for the selected property */}
                  {newReservation.propertyid && (
                    <select
                      name="rentalid"
                      value={newReservation.rentalid}
                      onChange={handleRentalSelect}
                      className="mb-2 border rounded-md py-2 px-4 w-full"
                    >
                      <option value="">Select Rental</option>
                      {properties
                        .find(
                          (property) =>
                            property.property_id === newReservation.propertyid
                        )
                        ?.rentals.map((rental) => (
                          <option
                            key={rental.rental_id}
                            value={rental.rental_id}
                          >
                            {rental.house_number}
                          </option>
                        ))}
                    </select>
                  )}

                  <input
                    type="text"
                    name="housenumber"
                    placeholder="House Number"
                    value={newReservation.housenumber}
                    readOnly
                    className="mb-2 border rounded-md py-2 px-4 w-full"
                  />

                  <DatePicker
                    selected={newReservation.entrydate}
                    onChange={(date) =>
                      setNewReservation({ ...newReservation, entrydate: date })
                    }
                    className="mb-2 border rounded-md py-2 px-4 w-full"
                  />

                  {/* Status Selection */}
                  <select
                    name="bookingstatus"
                    value={newReservation.bookingstatus}
                    onChange={handleInputChange}
                    className="mb-2 border rounded-md py-2 px-4 w-full"
                  >
                    <option value="">Select Booking Status</option>
                    <option value="SUCCESS">SUCCESS</option>
                    <option value="ENDED">ENDED</option>
                    <option value="PENDING">PENDING</option>
                  </select>

                  <select
                    name="depositpaid"
                    value={newReservation.depositpaid}
                    onChange={handleInputChange}
                    className="mb-2 border rounded-md py-2 px-4 w-full"
                  >
                    <option value="">Select Deposit Status</option>
                    <option value="TRUE">TRUE</option>
                    <option value="FALSE">FALSE</option>
                  </select>

                  <select
                    name="paymentstatus"
                    value={newReservation.paymentstatus}
                    onChange={handleInputChange}
                    className="mb-2 border rounded-md py-2 px-4 w-full"
                  >
                    <option value="">Select Payment Status</option>
                    <option value="SUCCESS">SUCCESS</option>
                    <option value="PENDING">PENDING</option>
                  </select>

                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={() => setIsAdding(false)}
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={handleAddReservation}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      disabled={isLoading}
                    >
                      {isLoading ? "Saving..." : "Save"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {/* Edit Reservation Modal */}
          {isEditing && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg w-1/2">
                <h2 className="text-xl font-semibold mb-4">Edit Reservation</h2>
                <form>
                  {/* Phone Field */}
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    value={newReservation.phone}
                    onChange={handleInputChange}
                    className="mb-2 border rounded-md py-2 px-4 w-full"
                  />

                  {/* Booking Status Field */}
                  <select
                    name="bookingstatus"
                    value={newReservation.bookingstatus}
                    onChange={handleInputChange}
                    className="mb-2 border rounded-md py-2 px-4 w-full"
                  >
                    <option value="">Select Booking Status</option>
                    <option value="SUCCESS">SUCCESS</option>
                    <option value="PENDING">PENDING</option>
                    <option value="ENDED">ENDED</option>
                  </select>

                  {/* Payment Status Field */}
                  <select
                    name="paymentstatus"
                    value={newReservation.paymentstatus}
                    onChange={handleInputChange}
                    className="mb-2 border rounded-md py-2 px-4 w-full"
                  >
                    <option value="">Select Payment Status</option>
                    <option value="PENDING">PENDING</option>
                    <option value="SUCCESS">SUCCESS</option>
                  </select>

                  {/* Deposit Status Field */}
                  <select
                    name="depositpaid"
                    value={newReservation.depositpaid}
                    onChange={handleInputChange}
                    className="mb-2 border rounded-md py-2 px-4 w-full"
                  >
                    <option value="">Select Deposit Status</option>
                    <option value="TRUE">TRUE</option>
                    <option value="FALSE">FALSE</option>
                  </select>

                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={() => setIsEditing(false)}
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={handleUpdateReservation}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      disabled={isLoading}
                    >
                      {isLoading ? "Saving..." : "Save"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {/* Reservation Table */}
          <table className="table-auto w-full mt-4">
            <thead>
              <tr>
                <th>User</th>
                <th>Email</th>
                <th>Phone</th>
                <th>User Id</th>
                <th>Property Id</th>
                <th>Rental Id</th>
                <th>House Number</th>
                <th>Entry Date</th>
                <th>Booking Status</th>
                <th>Deposit Paid</th>
                <th>Payment Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {reservations.map((reservation) => (
                <tr key={reservation.reservations_id}>
                  <td>{reservation.user_name}</td>
                  <td>{reservation.email}</td>
                  <td>{reservation.phone}</td>
                  <td>{reservation.user_id}</td>
                  <td>{reservation.property_id}</td>
                  <td>{reservation.room_id}</td>
                  <td>{reservation.house_no}</td>
                  <td>{reservation.entrydate}</td>
                  <td>{reservation.booking_status}</td>
                  <td>{reservation.deposit_paid}</td>
                  <td>{reservation.payment_status}</td>
                  <td>
                    <button
                      onClick={() => handleEditClick(reservation)}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default ManualReservations;
