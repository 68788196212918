import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faMobileAlt,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons"; // Import icons
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";
import { Oval } from "react-loader-spinner"; // Import loader

const WithdrawalsPage = () => {
  const [balance, setBalance] = useState(null);
  const [showBalance, setShowBalance] = useState(true);
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedMode, setSelectedMode] = useState(null);
  const [showMobileMoneyOptions, setShowMobileMoneyOptions] = useState(false);
  const [showCardPaymentOptions, setShowCardPaymentOptions] = useState(false);
  const [bankAccount, setBankAccount] = useState("");
  const [senderNames, setSenderNames] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const token = localStorage.getItem("token");
  const idnumber = localStorage.getItem("settle_idnumber");
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userId = userData.unique;
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/admin/househunter/fetchhunterbalance/${idnumber}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const fetchedBalance = response.data.response;
          setBalance(fetchedBalance);
          setWithdrawalAmount(fetchedBalance); // Set the withdrawal amount to the fetched balance
        })
        .catch((error) => {
          console.error("Error fetching balance:", error);
        });
    }

    axios
      .get("https://backend.inyumba.co.ke/iNyumba/admin/bank/fetch", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setBanks(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching banks:", error);
      });
  }, [token]);

  const handleBankSelect = (bankCode) => {
    setBankCode(bankCode);
    setSelectedBank(bankCode); // Update selectedBank state as well, if needed
  };

  const handleWithdrawalSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    const userDataString = localStorage.getItem("user");
    const userData = JSON.parse(userDataString);
    const userId = userData.unique;

    if (!token || !withdrawalAmount) {
      console.error("withdrawal amount, and channel are required.");
      toast.error("withdrawal amount, and channel are required.");
      return;
    }

    setIsLoading(true); // Set loading state to true

    try {
      let response;

      if (selectedMode === "mobilemoney") {
        const requestData = {
          amount: withdrawalAmount,
          phone_number: phoneNumber,
          channel: selectedChannel,
        };

        const jsonData = JSON.stringify(requestData);
        response = await axios.post(
          `https://backend.inyumba.co.ke/iNyumba/propertyhuntersettlement/${idnumber}`,
          jsonData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setWithdrawalAmount("");
        setPhoneNumber("");
        setSelectedChannel(null);
        setShowMobileMoneyOptions(false);
        setSelectedMode(null);
        setBankCode("");
        setSelectedBank("");

        if (
          response.data.responseCode == 200 &&
          response.data.response == "B2C processing request"
        ) {
          toast.success(response.data.response);
        }
        if (
          response.data.responseCode == 200 &&
          response.data.response ==
            "You have insufficient funds in your account. Transaction cost is 100"
        ) {
          toast.error(response.data.response);
        }
        if (
          response.data.responseCode == 200 &&
          response.data.response == "B2C blocked request"
        ) {
          toast.error(response.data.response);
        }
      } else if (selectedMode === "cards") {
        if (!selectedBank) {
          toast.error("Please select a bank");
          return;
        }

        const requestData = {
          amount: withdrawalAmount,
          bank_account: bankAccount,
          bank_code: bankCode,
          sendernames: senderNames,
        };

        const jsonData = JSON.stringify(requestData);

        response = await axios.post(
          `https://backend.inyumba.co.ke/iNyumba/propertyhuntersettlementcards/${idnumber}`,
          jsonData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setWithdrawalAmount("");
        setSenderNames("");
        setBankAccount("");
        setBankCode("");
        setSelectedChannel(null);
        setShowCardPaymentOptions(false);
        setSelectedMode(null);
        setSelectedBank("");

        if (
          response.data.responseCode == 200 &&
          response.data.response == "B2C processing request"
        ) {
          toast.success(response.data.response);
        }
        if (
          response.data.responseCode == 200 &&
          response.data.response ==
            "You have insufficient funds in your account. Transaction cost is 100"
        ) {
          toast.error(response.data.response);
        }
        if (
          response.data.responseCode == 200 &&
          response.data.response == "B2C blocked request"
        ) {
          toast.error(response.data.response);
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  const toggleShowBalance = () => {
    setShowBalance(!showBalance);
  };

  const handleModeSelection = (mode) => {
    setSelectedMode(mode);
    setShowMobileMoneyOptions(mode === "mobilemoney");
    setShowCardPaymentOptions(mode === "cards");
  };

  const handleMobileMoneyClick = (channel) => {
    setSelectedChannel(channel);
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-3xl font-semibold">Settlements</h1>
            <span className="text-1xl font-semibold mb-8">
              Date: {new Date().toLocaleDateString()}
            </span>
            <div className="flex items-center">
              {showBalance ? (
                <p className="mr-4">
                  Cummulative Balance for this Id Number:{" "}
                  {balance !== null ? `Ksh ${balance}` : "0"}
                </p>
              ) : (
                <p className="mr-4">
                  Balance: {balance !== null ? "*******" : "0"}
                </p>
              )}
              <FontAwesomeIcon
                icon={showBalance ? faEyeSlash : faEye}
                className="text-xl cursor-pointer"
                onClick={toggleShowBalance}
              />
            </div>
          </div>

          <div className="bg-white shadow-md rounded-md p-6">
            <h2 className="text-xl font-semibold mb-4">Settlment</h2>
            <div className="flex mb-4">
              <div className="mr-4 flex items-center">
                <button
                  className={`mr-4 flex items-center focus:outline-none ${
                    selectedMode === "mobilemoney"
                      ? "text-blue-500"
                      : "text-gray-500"
                  }`}
                  onClick={() => handleModeSelection("mobilemoney")}
                >
                  <FontAwesomeIcon
                    icon={faMobileAlt}
                    className="text-xl mr-2"
                  />
                  <span className="font-semibold">Mobile Money</span>
                </button>
                <button
                  className={`flex items-center focus:outline-none ${
                    selectedMode === "cards" ? "text-blue-500" : "text-gray-500"
                  }`}
                  onClick={() => handleModeSelection("cards")}
                >
                  <FontAwesomeIcon
                    icon={faCreditCard}
                    className="text-xl mr-2"
                  />
                  <span className="font-semibold">Card Payments</span>
                </button>
              </div>
            </div>
            {showMobileMoneyOptions && (
              <div className="mb-4 flex">
                <button
                  className={`mr-4 flex items-center focus:outline-none px-3 py-2 rounded-md ${
                    selectedChannel === "mpesa"
                      ? "bg-green-500 text-white"
                      : "bg-gray-200 text-gray-600 hover:bg-green-500 hover:text-white"
                  }`}
                  onClick={() => handleMobileMoneyClick("mpesa")}
                >
                  <FontAwesomeIcon
                    icon={faMobileAlt}
                    className="text-xl mr-2"
                  />
                  <span className="font-semibold">MPESA</span>
                </button>
                <button
                  className={`flex items-center focus:outline-none px-3 py-2 rounded-md ${
                    selectedChannel === "airtelmoney"
                      ? "bg-red-500 text-white"
                      : "bg-gray-200 text-gray-600 hover:bg-red-500 hover:text-white"
                  }`}
                  onClick={() => handleMobileMoneyClick("airtelmoney")}
                >
                  <FontAwesomeIcon
                    icon={faMobileAlt}
                    className="text-xl mr-2"
                  />
                  <span className="font-semibold">Airtel Money</span>
                </button>
              </div>
            )}
            <form onSubmit={handleWithdrawalSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="withdrawalAmount"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Amount
                </label>
                <input
                  type="number"
                  id="withdrawalAmount"
                  value={withdrawalAmount}
                  placeholder="Enter amount"
                  onChange={(e) => setWithdrawalAmount(e.target.value)}
                  className="block w-full border border-gray-300 rounded-md p-2"
                  required
                />
              </div>
              <div className={`mb-4 ${showCardPaymentOptions ? "hidden" : ""}`}>
                <label
                  htmlFor="phoneNumber"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter phone number"
                  className="block w-full border border-gray-300 rounded-md p-2"
                  required={!showCardPaymentOptions}
                />
              </div>
              {showCardPaymentOptions && (
                <>
                  <div className="mb-4">
                    <label
                      htmlFor="bankCode"
                      className="block text-gray-700 font-semibold mb-2"
                    >
                      Bank
                    </label>
                    <select
                      id="bankCode"
                      value={selectedBank}
                      onChange={(e) => handleBankSelect(e.target.value)}
                      className="block w-full border border-gray-300 rounded-md p-2"
                      required
                    >
                      <option value="">Select Bank</option>
                      {banks.map((bank) => (
                        <option key={bank.id} value={bank.bankcode}>
                          {bank.bankname}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="senderNames"
                      className="block text-gray-700 font-semibold mb-2"
                    >
                      Sender Names
                    </label>
                    <input
                      type="text"
                      id="senderNames"
                      value={senderNames}
                      onChange={(e) => setSenderNames(e.target.value)}
                      className="block w-full border border-gray-300 rounded-md p-2"
                      placeholder="Enter sender names"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="bankAccount"
                      className="block text-gray-700 font-semibold mb-2"
                    >
                      Bank Account Number
                    </label>
                    <input
                      type="text"
                      id="bankAccount"
                      value={bankAccount}
                      onChange={(e) => setBankAccount(e.target.value)}
                      className="block w-full border border-gray-300 rounded-md p-2"
                      placeholder="Enter bank account Number"
                      required
                    />
                  </div>
                </>
              )}
              <button
                type="submit"
                className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition-colors duration-300"
                disabled={isLoading} // Disable the button when loading
              >
                {isLoading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="white"
                    ariaLabel="loading"
                  />
                ) : (
                  "Submit Withdrawal"
                )}
              </button>
            </form>
          </div>
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default WithdrawalsPage;
