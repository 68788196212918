import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { EmailGuard } from "../App";
import { AuthGuard } from "../App";

const MoneyOutPage = () => {
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalUserBalance, setTotalUserBalance] = useState(0);
  const [totalCombined, setTotalCombined] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const token = localStorage.getItem("token");
    // Assuming token is stored in localStorage

    axios
      .all([
        axios.get(
          "https://backend.inyumba.co.ke/iNyumba/moneyout/totaldeposit",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ),
        axios.get(
          "https://backend.inyumba.co.ke/iNyumba/moneyout/totalbalance",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ),
        axios.get(
          "https://backend.inyumba.co.ke/iNyumba/moneyout/totaluserbalance",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ),
        axios.get(
          "https://backend.inyumba.co.ke/iNyumba/moneyout/totalcombined",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ),
      ])
      .then(
        axios.spread(
          (
            totalDepositRes,
            totalBalanceRes,
            totalUserBalanceRes,
            totalCombinedRes
          ) => {
            setTotalDeposit(totalDepositRes.data.response);
            setTotalBalance(totalBalanceRes.data.response);
            setTotalUserBalance(totalUserBalanceRes.data.response);
            setTotalCombined(totalCombinedRes.data.response);
          }
        )
      )
      .catch((error) => {
        console.error("Error fetching data:", error);
        toast.error(error.response.data.response);
      });
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <div className="bg-gray-100 rounded-lg p-8 shadow-lg">
            <h1 className="text-3xl font-semibold mb-4">Money Out</h1>
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-xl font-semibold mb-4">Total Deposit</h2>
                <p className="text-2xl font-bold">Ksh {totalDeposit}</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-xl font-semibold mb-4">Total Balance</h2>
                <p className="text-2xl font-bold">Ksh {totalBalance}</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-xl font-semibold mb-4">
                  Total User Balance
                </h2>
                <p className="text-2xl font-bold">Ksh {totalUserBalance}</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-xl font-semibold mb-4">Total Combined</h2>
                <p className="text-2xl font-bold">Ksh {totalCombined}</p>
              </div>
            </div>
          </div>
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default MoneyOutPage;
