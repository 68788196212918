import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { Oval } from "react-loader-spinner"; // Import loader

const PropertyHunter = () => {
  const [propertyhunteri, setPropertyHunter] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const navigate = useNavigate();
  const [newPropertyHunter, setNewPropertyHunter] = useState({
    property_id: "",
    hunterid: "",
    id_number: "",
    phone_number: "",
    email: "",
    name: "",
    percentage: "",
    balance: "",
  });

  useEffect(() => {
    fetchPropertyHunter();
  }, []);

  const fetchPropertyHunter = () => {
    const token = localStorage.getItem("token");
    axios
      .get("https://backend.inyumba.co.ke/iNyumba/admin/househunter/fetch", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPropertyHunter(response.data.response);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPropertyHunter({ ...newPropertyHunter, [name]: value });
  };

  const handleAddPropertyHunter = () => {
    setIsLoading(true); // Set loading state to true
    const token = localStorage.getItem("token");
    axios
      .post(
        "https://backend.inyumba.co.ke/iNyumba/admin/househunter/add",
        newPropertyHunter,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )

      .then((response) => {
        setIsModalOpen(false);
        setNewPropertyHunter({
          property_id: "",
          hunterid: "",
          id_number: "",
          phone_number: "",
          email: "",
          name: "",
          percentage: "",
          balance: "",
        });
        fetchPropertyHunter();
        setIsModalOpen(false);
        setIsLoading(false); // Set loading state to true
        toast.success(response.data.response);
      })
      .catch((error) => {
        setIsLoading(false); // Set loading state to true
        console.error("Error adding PropertyHunter:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleSettlePropertyHunter = (idnumber) => {
    localStorage.setItem("settle_idnumber", idnumber);
    navigate("/admin/settlepage"); // Replace with the actual route for the settle page
  };

  const handleDeletePropertyHunter = (propertyID) => {
    setIsLoading(true); // Set loading state to true

    const token = localStorage.getItem("token");
    axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/admin/househunter/delete/${propertyID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        fetchPropertyHunter();
        setIsLoading(false); // Set loading state to true
        toast.success(response.data.response);
      })
      .catch((error) => {
        setIsLoading(false); // Set loading state to true
        console.error("Error deleting propertyHunter:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleDownload = (fileType) => {
    const endpoint = `https://backend.inyumba.co.ke/iNyumba/report/propertyhunterviewreports`;

    const token = localStorage.getItem("token");

    // Fetch and download file
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { format: fileType },
        responseType: "blob", // Important for file download
      })
      .then((response) => {
        const fileExtension = fileType === "pdf" ? "pdf" : "csv";
        const fileName = `inyumbabankrecords.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Download is successfull");
      })
      .catch((error) => {
        console.error(`Error downloading ${fileType.toUpperCase()}:`, error);
        toast.error(error.response.data.response);
      });
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Property Hunter</h1>
          <div className="flex space-x-2">
            <button
              onClick={() => setIsModalOpen(true)}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
            >
              Add Property Hunter
            </button>
            <button
              onClick={() => handleDownload("csv")}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm"
            >
              <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
              Download
            </button>
            <button
              onClick={() => handleDownload("pdf")}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
            >
              <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
              Download
            </button>
          </div>
          <table className="w-full">
            <thead>
              <tr>
                <th className="px-4 py-2">Property Id</th>
                <th className="px-4 py-2">Created On</th>
                <th className="px-4 py-2">Hunter Id</th>
                <th className="px-4 py-2">Id Number</th>
                <th className="px-4 py-2">Phone Number</th>
                <th className="px-4 py-2">Email</th>
                <th className="px-4 py-2">Name</th>
                <th className="px-4 py-2">Percentage</th>
                <th className="px-4 py-2">Balance</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {propertyhunteri.map((propertyhunteri) => (
                <tr key={propertyhunteri.propertyid}>
                  <td className="border px-4 py-2">
                    {propertyhunteri.propertyid}
                  </td>
                  <td className="border px-4 py-2">
                    {new Date(propertyhunteri.created_at).toLocaleString()}
                  </td>
                  <td className="border px-4 py-2">
                    {propertyhunteri.hunterid}
                  </td>
                  <td className="border px-4 py-2">
                    {propertyhunteri.idnumber}
                  </td>
                  <td className="border px-4 py-2">
                    {propertyhunteri.phonenumber}
                  </td>
                  <td className="border px-4 py-2">{propertyhunteri.email}</td>
                  <td className="border px-4 py-2">{propertyhunteri.name}</td>
                  <td className="border px-4 py-2">
                    {propertyhunteri.percentage}
                  </td>
                  <td className="border px-4 py-2">
                    {propertyhunteri.balance}
                  </td>
                  <td className="border px-4 py-2">
                    <button
                      onClick={() =>
                        handleDeletePropertyHunter(propertyhunteri.propertyid)
                      }
                      disabled={isLoading}
                      className={`${
                        isLoading
                          ? "bg-gray-500"
                          : "bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded mr-2"
                      } text-white py-2 px-4 rounded-md focus:outline-none`}
                    >
                      {isLoading ? (
                        <Oval
                          height={20}
                          width={20}
                          color="white"
                          visible={true}
                          ariaLabel="loading-indicator"
                          secondaryColor="white"
                        />
                      ) : (
                        "Delete"
                      )}
                    </button>
                    {propertyhunteri.balance > 0 && (
                      <button
                        onClick={() =>
                          handleSettlePropertyHunter(propertyhunteri.idnumber)
                        }
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded"
                      >
                        Settle
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {isModalOpen && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
            <div className="bg-white p-6 rounded-lg w-96">
              <h2 className="text-lg font-semibold mb-4">
                Add Property Hunter
              </h2>
              <input
                type="text"
                name="property_id"
                value={newPropertyHunter.property_id}
                onChange={handleInputChange}
                placeholder="Property Id"
                className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
              />
              <input
                type="text"
                name="property_id"
                value={newPropertyHunter.hunterid}
                onChange={handleInputChange}
                placeholder="Property Hunter Id"
                className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
              />
              <input
                type="text"
                name="id_number"
                value={newPropertyHunter.id_number}
                onChange={handleInputChange}
                placeholder="Id Number"
                className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
              />
              <input
                type="text"
                name="phone_number"
                value={newPropertyHunter.phone_number}
                onChange={handleInputChange}
                placeholder="Phone Number start with 2547 or 2541.."
                className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
              />
              <input
                type="text"
                name="email"
                value={newPropertyHunter.email}
                onChange={handleInputChange}
                placeholder="Email"
                className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
              />
              <input
                type="text"
                name="name"
                value={newPropertyHunter.name}
                onChange={handleInputChange}
                placeholder="Name"
                className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
              />
              <input
                type="text"
                name="percentage"
                value={newPropertyHunter.percentage}
                onChange={handleInputChange}
                placeholder="Percentage in decimal"
                className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
              />
              <input
                type="text"
                name="balance"
                value={newPropertyHunter.balance}
                onChange={handleInputChange}
                placeholder="Balance"
                className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
              />
              <div className="flex justify-end">
                <button
                  onClick={handleAddPropertyHunter}
                  disabled={isLoading}
                  className={`${
                    isLoading
                      ? "bg-gray-500"
                      : "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                  } text-white py-2 px-4 rounded-md focus:outline-none`}
                >
                  {isLoading ? (
                    <Oval
                      height={20}
                      width={20}
                      color="white"
                      visible={true}
                      ariaLabel="loading-indicator"
                      secondaryColor="white"
                    />
                  ) : (
                    "Add Property Hunter"
                  )}
                </button>

                <button
                  onClick={() => setIsModalOpen(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </AuthGuard>
    </EmailGuard>
  );
};

export default PropertyHunter;
