import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import { AuthGuard } from "../App";
import { EmailGuard } from "../App";

const PropertyTrashBillPage = () => {
  const [propertyId, setPropertyId] = useState("");
  const [trashBills, setTrashBills] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [billToUpdate, setBillToUpdate] = useState(null);

  const fetchTrashBills = () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/admin/trashbill/getall/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setTrashBills(response.data.response);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching trash bills:", error);
        toast.error(
          error.response?.data?.response || "Error fetching trash bills"
        );
        setIsLoading(false);
      });
  };

  const handleDeleteTrashBill = (trashBillId) => {
    const token = localStorage.getItem("token");
    axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/admin/trashbill/deletetrashbill/${trashBillId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        toast.success("Trash bill deleted successfully.");
        fetchTrashBills();
      })
      .catch((error) => {
        console.error("Error deleting trash bill:", error);
        toast.error("Failed to delete trash bill.");
      });
  };

  const handleUpdateTrashBill = (trashBillId) => {
    const token = localStorage.getItem("token");
    const data = {
      amount,
    };

    axios
      .patch(
        `https://backend.inyumba.co.ke/iNyumba/admin/trashbill/updatetrashbillid/${trashBillId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        toast.success("Trash bill updated successfully.");
        fetchTrashBills();
        setUpdateModalOpen(false); // Close the update modal after success
        setAmount(""); // Reset input value
      })
      .catch((error) => {
        console.error("Error updating trash bill:", error);
        toast.error("Failed to update trash bill.");
        setUpdateModalOpen(false); // Close the update modal after error
        setAmount(""); // Reset input value
      });
  };

  return (
    <EmailGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Property Trash Bills</h1>
          <div className="flex mb-4">
            <input
              type="text"
              value={propertyId}
              onChange={(e) => setPropertyId(e.target.value)}
              placeholder="Enter Property ID"
              className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
            />
            <button
              onClick={fetchTrashBills}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
            >
              Search
            </button>
          </div>

          {updateModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <h2 className="text-2xl">Update Trash Bill</h2>
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Enter Amount"
                  className="border border-gray-300 rounded-md py-2 px-4 mb-2 w-full"
                />
                <div className="modal-footer">
                  <button
                    onClick={() =>
                      handleUpdateTrashBill(billToUpdate.trashbillid)
                    }
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    disabled={!amount}
                  >
                    Update Trash Bill
                  </button>
                  <button
                    onClick={() => setUpdateModalOpen(false)}
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded ml-2"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          {isLoading ? (
            <div className="flex justify-center">
              <Oval
                height={40}
                width={40}
                color="blue"
                ariaLabel="loading-indicator"
              />
            </div>
          ) : (
            <table className="mt-4 w-full">
              <thead>
                <tr>
                  <th className="px-4 py-2">Trash Bill ID</th>
                  <th className="px-4 py-2">Rental ID</th>
                  <th className="px-4 py-2">House Number</th>
                  <th className="px-4 py-2">User ID</th>
                  <th className="px-4 py-2">Amount</th>
                  <th className="px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {trashBills.map((bill) => (
                  <tr key={bill.trashbillid}>
                    <td className="border px-4 py-2">{bill.trashbillid}</td>
                    <td className="border px-4 py-2">{bill.rentalid}</td>
                    <td className="border px-4 py-2">{bill.housenumber}</td>
                    <td className="border px-4 py-2">{bill.userid}</td>
                    <td className="border px-4 py-2">{bill.amount}</td>
                    <td className="border px-4 py-2">
                      <button
                        onClick={() => {
                          setBillToUpdate(bill);
                          setAmount(bill.amount);
                          setUpdateModalOpen(true);
                        }}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                      >
                        Update
                      </button>
                      <button
                        onClick={() => handleDeleteTrashBill(bill.trashbillid)}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </AuthGuard>
    </EmailGuard>
  );
};

export default PropertyTrashBillPage;
